import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import { Helmet } from "react-helmet";
import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import Spinner from '../../layout/Spinner';
import { getFiliali } from '../../../actions/filiale';
import { getGiorniEccezioneAssenteismo } from '../../../actions/dipendente';
import { getFestivita } from '../../../actions/impostazione';

const Assenteismo = ({ auth: { user }, dipendente: { datiAssenteismo }, getGiorniEccezioneAssenteismo, getFestivita, impostazione: { festivita }, filiale: { filiali }, getFiliali, alert }) => {

    const [dataInizioInput, setDataInizioInput] = useState((setHours(setMinutes(startOfYear(new Date()), 0), 0)));
    const [dataFineInput, setDataFineInput] = useState((setHours(setMinutes(endOfYear(new Date()), 59), 23)));
    const [gpgSelected, setGpgSelected] = useState(true);
    const [otsSelected, setOtsSelected] = useState(true);
    const [somministratoSelected, setSomministratoSelected] = useState(true);
    const [filialiList, setFilialiList] = useState([]);
    const [filtraFiliale, setFiltraFiliale] = useState(null);


    const [csvData, setCsvData] = useState([]);

    const headerA =
        [
            { label: 'dipendente', key: 'dipendente' },
            { label: 'Gennaio', key: 'gennaio_label' },
            { label: 'car', key: 'car_gennaio' },
            { label: 'M', key: 'm_gennaio' },
            { label: 'I', key: 'i_gennaio' },
            { label: '104', key: 'ph_gennaio' },
            { label: 'AVIS', key: 'avis_gennaio' },
            { label: 'MAT', key: 'mat_gennaio' },
            { label: 'MAT. FAC.', key: 'mat_fac_gennaio' },
            { label: 'Cong. bien.', key: 'cong_bien_gennaio' },
            { label: 'Perm. sind.', key: 'perm_sind_gennaio' },
            { label: 'Cong. Obbl. Papà', key: 'cong_obbl_papa_gennaio' },
            { label: 'Mal. Osp.', key: 'malat_osp_gennaio' },
            { label: 'Febbraio', key: 'febbraio_label' },
            { label: 'car', key: 'car_febbraio' },
            { label: 'M', key: 'm_febbraio' },
            { label: 'I', key: 'i_febbraio' },
            { label: '104', key: 'ph_febbraio' },
            { label: 'AVIS', key: 'avis_febbraio' },
            { label: 'MAT', key: 'mat_febbraio' },
            { label: 'MAT. FAC.', key: 'mat_fac_febbraio' },
            { label: 'Cong. bien.', key: 'cong_bien_febbraio' },
            { label: 'Perm. sind.', key: 'perm_sind_febbraio' },
            { label: 'Cong. Obbl. Papà', key: 'cong_obbl_papa_febbraio' },
            { label: 'Mal. Osp.', key: 'malat_osp_febbraio' },
            { label: 'Marzo', key: 'marzo_label' },
            { label: 'car', key: 'car_marzo' },
            { label: 'M', key: 'm_marzo' },
            { label: 'I', key: 'i_marzo' },
            { label: '104', key: 'ph_marzo' },
            { label: 'AVIS', key: 'avis_marzo' },
            { label: 'MAT', key: 'mat_marzo' },
            { label: 'MAT. FAC.', key: 'mat_fac_marzo' },
            { label: 'Cong. bien.', key: 'cong_bien_marzo' },
            { label: 'Perm. sind.', key: 'perm_sind_marzo' },
            { label: 'Cong. Obbl. Papà', key: 'cong_obbl_papa_marzo' },
            { label: 'Mal. Osp.', key: 'malat_osp_marzo' },
            { label: 'Aprile', key: 'aprile_label' },
            { label: 'car', key: 'car_aprile' },
            { label: 'M', key: 'm_aprile' },
            { label: 'I', key: 'i_aprile' },
            { label: '104', key: 'ph_aprile' },
            { label: 'AVIS', key: 'avis_aprile' },
            { label: 'MAT', key: 'mat_aprile' },
            { label: 'MAT. FAC.', key: 'mat_fac_aprile' },
            { label: 'Cong. bien.', key: 'cong_bien_aprile' },
            { label: 'Perm. sind.', key: 'perm_sind_aprile' },
            { label: 'Cong. Obbl. Papà', key: 'cong_obbl_papa_aprile' },
            { label: 'Mal. Osp.', key: 'malat_osp_aprile' },
            { label: 'Maggio', key: 'maggio_label' },
            { label: 'car', key: 'car_maggio' },
            { label: 'M', key: 'm_maggio' },
            { label: 'I', key: 'i_maggio' },
            { label: '104', key: 'ph_maggio' },
            { label: 'AVIS', key: 'avis_maggio' },
            { label: 'MAT', key: 'mat_maggio' },
            { label: 'MAT. FAC.', key: 'mat_fac_maggio' },
            { label: 'Cong. bien.', key: 'cong_bien_maggio' },
            { label: 'Perm. sind.', key: 'perm_sind_maggio' },
            { label: 'Cong. Obbl. Papà', key: 'cong_obbl_papa_maggio' },
            { label: 'Mal. Osp.', key: 'malat_osp_maggio' },
            { label: 'Giugno', key: 'giugno_label' },
            { label: 'car', key: 'car_giugno' },
            { label: 'M', key: 'm_giugno' },
            { label: 'I', key: 'i_giugno' },
            { label: '104', key: 'ph_giugno' },
            { label: 'AVIS', key: 'avis_giugno' },
            { label: 'MAT', key: 'mat_giugno' },
            { label: 'MAT. FAC.', key: 'mat_fac_giugno' },
            { label: 'Cong. bien.', key: 'cong_bien_giugno' },
            { label: 'Perm. sind.', key: 'perm_sind_giugno' },
            { label: 'Cong. Obbl. Papà', key: 'cong_obbl_papa_giugno' },
            { label: 'Mal. Osp.', key: 'malat_osp_giugno' },
            { label: 'Luglio', key: 'luglio_label' },
            { label: 'car', key: 'car_luglio' },
            { label: 'M', key: 'm_luglio' },
            { label: 'I', key: 'i_luglio' },
            { label: '104', key: 'ph_luglio' },
            { label: 'AVIS', key: 'avis_luglio' },
            { label: 'MAT', key: 'mat_luglio' },
            { label: 'MAT. FAC.', key: 'mat_fac_luglio' },
            { label: 'Cong. bien.', key: 'cong_bien_luglio' },
            { label: 'Perm. sind.', key: 'perm_sind_luglio' },
            { label: 'Cong. Obbl. Papà', key: 'cong_obbl_papa_luglio' },
            { label: 'Mal. Osp.', key: 'malat_osp_luglio' },
            { label: 'Agosto', key: 'agosto_label' },
            { label: 'car', key: 'car_agosto' },
            { label: 'M', key: 'm_agosto' },
            { label: 'I', key: 'i_agosto' },
            { label: '104', key: 'ph_agosto' },
            { label: 'AVIS', key: 'avis_agosto' },
            { label: 'MAT', key: 'mat_agosto' },
            { label: 'MAT. FAC.', key: 'mat_fac_agosto' },
            { label: 'Cong. bien.', key: 'cong_bien_agosto' },
            { label: 'Perm. sind.', key: 'perm_sind_agosto' },
            { label: 'Cong. Obbl. Papà', key: 'cong_obbl_papa_agosto' },
            { label: 'Mal. Osp.', key: 'malat_osp_agosto' },
            { label: 'Settembre', key: 'settembre_label' },
            { label: 'car', key: 'car_settembre' },
            { label: 'M', key: 'm_settembre' },
            { label: 'I', key: 'i_settembre' },
            { label: '104', key: 'ph_settembre' },
            { label: 'AVIS', key: 'avis_settembre' },
            { label: 'MAT', key: 'mat_settembre' },
            { label: 'MAT. FAC.', key: 'mat_fac_settembre' },
            { label: 'Cong. bien.', key: 'cong_bien_settembre' },
            { label: 'Perm. sind.', key: 'perm_sind_settembre' },
            { label: 'Cong. Obbl. Papà', key: 'cong_obbl_papa_settembre' },
            { label: 'Mal. Osp.', key: 'malat_osp_settembre' },
            { label: 'Ottobre', key: 'ottobre_label' },
            { label: 'car', key: 'car_ottobre' },
            { label: 'M', key: 'm_ottobre' },
            { label: 'I', key: 'i_ottobre' },
            { label: '104', key: 'ph_ottobre' },
            { label: 'AVIS', key: 'avis_ottobre' },
            { label: 'MAT', key: 'mat_ottobre' },
            { label: 'MAT. FAC.', key: 'mat_fac_ottobre' },
            { label: 'Cong. bien.', key: 'cong_bien_ottobre' },
            { label: 'Perm. sind.', key: 'perm_sind_ottobre' },
            { label: 'Cong. Obbl. Papà', key: 'cong_obbl_papa_ottobre' },
            { label: 'Mal. Osp.', key: 'malat_osp_ottobre' },
            { label: 'Novembre', key: 'novembre_label' },
            { label: 'car', key: 'car_novembre' },
            { label: 'M', key: 'm_novembre' },
            { label: 'I', key: 'i_novembre' },
            { label: '104', key: 'ph_novembre' },
            { label: 'AVIS', key: 'avis_novembre' },
            { label: 'MAT', key: 'mat_novembre' },
            { label: 'MAT. FAC.', key: 'mat_fac_novembre' },
            { label: 'Cong. bien.', key: 'cong_bien_novembre' },
            { label: 'Perm. sind.', key: 'perm_sind_novembre' },
            { label: 'Cong. Obbl. Papà', key: 'cong_obbl_papa_novembre' },
            { label: 'Mal. Osp.', key: 'malat_osp_novembre' },
            { label: 'Dicembre', key: 'dicembre_label' },
            { label: 'car', key: 'car_dicembre' },
            { label: 'M', key: 'm_dicembre' },
            { label: 'I', key: 'i_dicembre' },
            { label: '104', key: 'ph_dicembre' },
            { label: 'AVIS', key: 'avis_dicembre' },
            { label: 'MAT', key: 'mat_dicembre' },
            { label: 'MAT. FAC.', key: 'mat_fac_dicembre' },
            { label: 'Cong. bien.', key: 'cong_bien_dicembre' },
            { label: 'Perm. sind.', key: 'perm_sind_dicembre' },
            { label: 'Cong. Obbl. Papà', key: 'cong_obbl_papa_dicembre' },
            { label: 'Mal. Osp.', key: 'malat_osp_dicembre' },
            { label: 'Totali', key: 'totali_label' },
            { label: 'Tot. car.', key: 'car_totale' },
            { label: 'Tot. Malattia', key: 'm_totale' },
            { label: 'Tot. inf.', key: 'i_totale' },
            { label: 'Tot. 104', key: 'ph_totale' },
            { label: 'Tot. Avis', key: 'avis_totale' },
            { label: 'Tot. Mat.', key: 'mat_totale' },
            { label: 'Tot. Mat. Fac', key: 'mat_fac_totale' },
            { label: 'Tot. Cong. Biennale', key: 'cong_bien_totale' },
            { label: 'Tot. Perm. Sind.', key: 'perm_sind_totale' },
            { label: 'Tot. Cong. Obbl. Papà', key: 'cong_obbl_papa_totale' },
            { label: 'Tot. Mal. Osp.', key: 'malat_osp_totale' },

        ];

        const headerB =
        [
            { label: 'dipendente', key: 'dipendente' }
            
        ];


    const [loadingRichieste, setLoadingRichieste] = useState(false);
    const [nomeFiliale, setNomeFiliale] = useState("");

    useEffect(() => {
        const size = alert.length;
        if(size>0){
            setLoadingRichieste(false)
        }
    }, [alert]);

    useEffect(() => {
        if (user && user.filiale && user.filiale.nome) {
            setNomeFiliale(user.filiale.nome)
        }
    }, [user]);

    useEffect(() => {
        getFestivita()
    }, []);

    useEffect(() => {
        if (user && user.role == "organizzazione") {
            getFiliali();
        }
    }, [user]);

    useEffect(() => {
        if (user && user.role == "organizzazione"){
            if (filiali.length > 0) {
                let array = [];
                filiali.map(filiale => {
                    const index = user.vistaFiliali.findIndex(el => el._id == filiale._id);
                    if(index >= 0){
                        array.push(filiale)
                        setFiltraFiliale(filiale._id)
                    }
                })
                setFilialiList(array)
            }
        }
    }, [filiali]);

    useEffect(() => {
        let array = []
        let arrayGiornoEccezione = []
        let arrayDipendenti = []
        let meseDivisore = 0
        if (datiAssenteismo.giorniEccezione.length >= 0 && datiAssenteismo.dipendenti.length > 0){
            arrayGiornoEccezione = [...datiAssenteismo.giorniEccezione]
            arrayDipendenti = [...datiAssenteismo.dipendenti]
                if(gpgSelected && !otsSelected && !somministratoSelected){
                    arrayGiornoEccezione = arrayGiornoEccezione.filter(el => { return el.dipendente.qualifica == "gpg" && !el.dipendente.somministrato})
                    arrayDipendenti = arrayDipendenti.filter(el => { return el.qualifica == "gpg" && !el.somministrato})
                }
                if(!gpgSelected && otsSelected && !somministratoSelected){
                    arrayGiornoEccezione = arrayGiornoEccezione.filter(el => { return el.dipendente.qualifica == "ots" && !el.dipendente.somministrato})
                    arrayDipendenti = arrayDipendenti.filter(el => { return el.qualifica == "ots" && !el.somministrato})
                }
                if(!gpgSelected && !otsSelected && !somministratoSelected){
                    arrayGiornoEccezione = arrayGiornoEccezione.filter(el => { return el.dipendente.qualifica != "gpg" && el.dipendente.qualifica != "ots" && !el.dipendente.somministrato})
                    arrayDipendenti = arrayDipendenti.filter(el => { return el.qualifica != "gpg" && el.qualifica != "ots" && !el.somministrato})
                }if(gpgSelected && !otsSelected && somministratoSelected){
                    arrayGiornoEccezione = arrayGiornoEccezione.filter(el => { return el.dipendente.qualifica == "gpg"})
                    arrayDipendenti = arrayDipendenti.filter(el => { return el.qualifica == "gpg"})
                }
                if(!gpgSelected && otsSelected && somministratoSelected){
                    arrayGiornoEccezione = arrayGiornoEccezione.filter(el => { return el.dipendente.qualifica == "ots"})
                    arrayDipendenti = arrayDipendenti.filter(el => { return el.qualifica == "ots"})
                }
                if(!gpgSelected && !otsSelected && somministratoSelected){
                    arrayGiornoEccezione = arrayGiornoEccezione.filter(el => { return el.dipendente.somministrato})
                    arrayDipendenti = arrayDipendenti.filter(el => { return el.somministrato })
                }if(gpgSelected && otsSelected && !somministratoSelected){
                    arrayGiornoEccezione = arrayGiornoEccezione.filter(el => { return !el.dipendente.somministrato})
                    arrayDipendenti = arrayDipendenti.filter(el => { return !el.somministrato })
                }
            
            



            arrayGiornoEccezione.map(el => {
                let mese = new Date(el.dataInizio).getMonth()
                if(mese > meseDivisore){
                    meseDivisore = mese
                }
                let anno = new Date(el.dataInizio).getFullYear()
                let nomeMese = getNomeMese(mese)
                let index = array.findIndex(a => { return el.dipendente._id == a.dipendente._id })
                let durata = lunghezzagiornoEccezione(el)
                if (index > -1) {
                    array[index].mesi[nomeMese].elementi.push(el)
                    if (durata > 0) {
                        //array[index].mesi[nomeMese].elementi.push(el)
                        for (let i = 0; i < durata; i++) {
                            let newObj = JSON.parse(JSON.stringify(el));
                            if (i == 0) {
                                let newData = new Date(newObj.dataInizio)
                                newObj.dataInizio = new Date(newData)
                                let newMese = new Date(newObj.dataInizio).getMonth()
                                let nomeNewMese = getNomeMese(newMese)
                                let newAnno = new Date(el.dataInizio).getFullYear()
                                if (anno == newAnno) {
                                    array[index].mesi[nomeNewMese].elementi.push(newObj)
                                }
                            } else {
                                let newData = addDays(new Date(newObj.dataInizio), i)
                                newObj.dataInizio = new Date(newData)
                                let newMese = new Date(newObj.dataInizio).getMonth()
                                let nomeNewMese = getNomeMese(newMese)
                                let newAnno = new Date(el.dataInizio).getFullYear()
                                if (anno == newAnno) {
                                    array[index].mesi[nomeNewMese].elementi.push(newObj)
                                }
                            }
                        }
                    }
                } else {
                    let obj = { dipendente: el.dipendente, mesi: { gennaio: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, febbraio: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, marzo: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, aprile: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, maggio: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, giugno: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, luglio: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, agosto: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, settembre: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, ottobre: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, novembre: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, dicembre: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] } } }
                    //obj.mesi[nomeMese].elementi.push(el)
                    for (let i = 0; i <= durata; i++) {
                        let newObj = JSON.parse(JSON.stringify(el));
                        if (i == 0) {
                            let primaNewData = new Date(newObj.dataInizio)
                            newObj.dataInizio = new Date(primaNewData)
                            let primaNewMese = new Date(newObj.dataInizio).getMonth()
                            let primaNomeNewMese = getNomeMese(primaNewMese)
                            let newAnno = new Date(el.dataInizio).getFullYear()
                            if (anno == newAnno) {
                                obj.mesi[primaNomeNewMese].elementi.push(newObj)
                            }
                        } else {
                            let newData = addDays(new Date(newObj.dataInizio), i)
                            newObj.dataInizio = new Date(newData)
                            let newMese = new Date(newObj.dataInizio).getMonth()
                            let nomeNewMese = getNomeMese(newMese)
                            let newAnno = new Date(el.dataInizio).getFullYear()
                            if (anno == newAnno) {
                                obj.mesi[nomeNewMese].elementi.push(newObj)
                            }
                        }
                    }
                    array.push(obj)
                }
            })
        }
        array = calcoloEccezione({ dipendenti: array, eccezione: "MA" })
        array = calcoloEccezione({ dipendenti: array, eccezione: "MT" })
        array = calcoloEccezione({ dipendenti: array, eccezione: "MF" })
        array = calcoloEccezione({ dipendenti: array, eccezione: "IN" })
        array = calcoloEccezione({ dipendenti: array, eccezione: "PH" })
        array = calcoloEccezione({ dipendenti: array, eccezione: "DS" })
        array = calcoloEccezione({ dipendenti: array, eccezione: "CD" })
        array = calcoloEccezione({ dipendenti: array, eccezione: "PS" })
        array = calcoloEccezione({ dipendenti: array, eccezione: "CP" })
        array = calcoloEccezione({ dipendenti: array, eccezione: "MO" })



        array = calcolaCarenza({ dipendenti: array })
        let newArray = aggiungiDipendentiMancanti(arrayDipendenti, array)
        //console.log("DIMENSIONE 1: " + newArray.lengh)
        newArray = togliDipendentiCessati(newArray)
        //console.log("DIMENSIONE 2: " + newArray.lengh)
        newArray.sort((a, b) => (a.dipendente.cognome > b.dipendente.cognome) ? 1 : -1)
        let arrayExcel = generaStrutturaExcel(newArray)

        arrayExcel = aggiungiTotale(arrayExcel, "car", "Tot. Carenza")
        arrayExcel = aggiungiTotale(arrayExcel, "m", "Tot. inps")
        arrayExcel = aggiungiTotale2Campi(arrayExcel, "Tot. Carenza", "Tot. inps", "Tot. Malattia", "m")
        arrayExcel = aggiungiTotale(arrayExcel, "i", "Tot. inf")
        arrayExcel = aggiungiTotale(arrayExcel, "ph", "Tot. 104")
        arrayExcel = aggiungiTotale(arrayExcel, "avis", "Tot. Avis")
        arrayExcel = aggiungiTotale(arrayExcel, "mat", "Tot. Mat.")
        arrayExcel = aggiungiTotale(arrayExcel, "mat_fac", "Tot. Mat. fac.")
        arrayExcel = aggiungiTotale(arrayExcel, "cong_bien", "Tot. Cong. Bien.")
        arrayExcel = aggiungiTotale(arrayExcel, "perm_sind", "Tot. Perm. Sind.")
        arrayExcel = aggiungiTotale(arrayExcel, "cong_obbl_papa", "Tot. Cong. Obbl. Papà")
        arrayExcel = aggiungiTotale(arrayExcel, "malat_osp", "Tot. Mal. Osp.")

        setCsvData(arrayExcel)
        if (arrayExcel.length > 10) {
            
            saveAsExcel(arrayExcel, headerA, headerB, newArray, meseDivisore+1)
        }
    }, [datiAssenteismo]);

    const aggiungiDipendentiMancanti = (dipendenti, array) => {
        dipendenti.map(dipendente => {
            let index = array.findIndex(el => el.dipendente._id == dipendente._id)
            if (index == -1) {
                let obj = { dipendente: dipendente, mesi: { gennaio: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, febbraio: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, marzo: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, aprile: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, maggio: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, giugno: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, luglio: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, agosto: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, settembre: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, ottobre: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, novembre: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] }, dicembre: { elementi: [], carenza: 0, MA: [], MT: [], MF: [], IN: [], PH: [], DS: [], CD: [], PS: [], CP:[], MO: [] } } }
                array.push(obj)
            }
        })
        return array
    }

    const checkDipendentiAttivi = (dipendenti, dataConfronto) => {
        let dataEndConfronto = lastDayOfMonth(new Date(dataConfronto))
        let contatore = 0
        dipendenti.map(el => {
            if((el.dipendente.dataFineAss == null) && (new Date(el.dipendente.dataInizioAss) <= dataEndConfronto)){
                contatore = contatore + 1
            }else if((new Date(el.dipendente.dataFineAss) >= dataConfronto) && (new Date(el.dipendente.dataInizioAss) <= dataEndConfronto)){
                contatore = contatore + 1
            }
        })
        return contatore
    }

    const togliDipendentiCessati = (dipendenti) => {
        let array = []
        dipendenti.map(el => {
            if(el.dipendente.dataFineAss == null){
                //console.log("NULL")
                //console.log(el.dipendente.cognome)
                //console.log(el.dipendente.dataFineAss)
                //console.log("__________")
                array.push(el)
            }else if(new Date(el.dipendente.dataFineAss) > dataInizioInput){
                //console.log("MAGG")
                //console.log(el.dipendente.cognome)
                //console.log(el.dipendente.dataFineAss)
                //console.log("__________")
                array.push(el)
            }
        })
        return array
    }

    const lunghezzagiornoEccezione = (elemento) => {
        const giornoInizio = new Date(elemento.dataInizio).getDate()
        const giornoFine = new Date(elemento.dataFine).getDate()
        if (giornoFine >= giornoInizio) {
            return giornoFine - giornoInizio
        } else {
            let ultimoGiornoMese = endOfYear(new Date(elemento.dataInizio)).getDate()
            let somma = (ultimoGiornoMese - giornoInizio) + giornoFine
            return somma
        }
    }



    const calcolaCarenza = ({ dipendenti }) => {
        dipendenti.map(dipendente => {
            let arrayCarenze = []
            let arrayMalattie = []
            for (var mese in dipendente.mesi) {
                if (dipendente.mesi[mese].MA.length > 0) {
                    arrayMalattie = arrayMalattie.concat(dipendente.mesi[mese].MA)
                }
                dipendente.mesi[mese]['carenza'] = 0
            }
            if (arrayMalattie.length > 0) {
                arrayCarenze = ottieniPeriodiCarenzaConsecutivi(arrayMalattie)
                arrayCarenze.map(el => {
                    dipendente.mesi[el.meseInizio].carenza = dipendente.mesi[el.meseInizio].carenza + el.carenza
                })
            }
        })


        //let isFestivo = checkIfDataIsFestivo(new Date(incarico.dataInizio), festivita)

        return dipendenti
    }

    const toIsoString = (date) => {
        const pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

        return pad(date.getDate()) +
            '-' + pad(date.getMonth() + 1) +
            '-' + date.getFullYear()
    }

    const ottieniPeriodiCarenzaConsecutivi = (arrayDate) => {
        arrayDate.map(el => {
            //el.dataInizio = toIsoString(new Date(el.dataInizio))
        })

        // Ordina le date in ordine cronologico
        arrayDate.sort(function (a, b) {
            return new Date(a.dataInizio) - new Date(b.dataInizio);
        });

        // Array per i periodi consecutivi di date
        var periodi = [];

        // Variabili per tenere traccia dei periodi correnti
        var obj = {
            dataInizio: null,
            dataFine: null,
            meseInizio: null,
            numeroMeseInizio: null,
            meseFine: null,
            numeroMeseFine: null,
            lunghezza: 0,
            carenza: 0,
            meseMultiplo: false,
            giorniMese1: 0,
            giorniMese2: 0
        };

        for (var i = 0; i < arrayDate.length; i++) {
            var dataCorrente = new Date(arrayDate[i].dataInizio);
            var dataSuccessiva = (i < arrayDate.length - 1) ? new Date(arrayDate[i + 1].dataInizio) : null;
            if (dataSuccessiva) {
                if (obj.dataInizio === null) {
                    // Primo periodo
                    obj.dataInizio = dataCorrente;
                    obj.meseInizio = getNomeMeseItaliano(dataCorrente.getMonth());
                    obj.numeroMeseInizio = dataCorrente.getMonth();
                    obj.lunghezza = 1;
                    obj.dataFine = dataCorrente;
                    obj.meseFine = obj.meseInizio
                    obj.numeroMeseFine = dataCorrente.getMonth();

                } else if (isDataConsecutiva(dataCorrente, obj.dataFine)) {
                    // La data corrente è consecutiva alla data di fine del periodo corrente
                    obj.dataFine = dataCorrente;
                    obj.meseFine = getNomeMeseItaliano(dataCorrente.getMonth());
                    obj.numeroMeseFine = dataCorrente.getMonth();
                    obj.lunghezza++;
                    if (obj.meseInizio !== obj.meseFine) {
                        obj.meseMultiplo = true;
                    }
                } else {
                    // Aggiungi il periodo corrente all'array dei periodi
                    periodi.push(obj);

                    // Crea un nuovo periodo corrente
                    obj = {
                        dataInizio: dataCorrente,
                        dataFine: dataCorrente,
                        meseInizio: getNomeMeseItaliano(dataCorrente.getMonth()),
                        meseFine: getNomeMeseItaliano(dataCorrente.getMonth()),
                        numeroMeseInizio: dataCorrente.getMonth(),
                        numeroMeseFine: dataCorrente.getMonth(),
                        lunghezza: 1,
                        carenza: 0,
                        meseMultiplo: false,
                        giorniMese1: 0,
                        giorniMese2: 0
                    };
                }
            } else {
                if (obj.dataInizio === null) {

                    // Primo periodo
                    obj.dataInizio = dataCorrente;
                    obj.meseInizio = getNomeMeseItaliano(dataCorrente.getMonth());
                    obj.numeroMeseInizio = dataCorrente.getMonth();
                    obj.lunghezza = 1;
                    obj.dataFine = dataCorrente;
                    obj.numeroMeseFine = dataCorrente.getMonth();
                    obj.meseFine = obj.meseInizio
                    periodi.push(obj);
                } else if (isDataConsecutiva(dataCorrente, obj.dataFine)) {
                    // La data corrente è consecutiva alla data di fine del periodo corrente
                    obj.dataFine = dataCorrente;
                    obj.meseFine = getNomeMeseItaliano(dataCorrente.getMonth());
                    obj.numeroMeseFine = dataCorrente.getMonth();
                    obj.lunghezza++;
                    if (obj.meseInizio !== obj.meseFine) {
                        obj.meseMultiplo = true;
                    }
                    periodi.push(obj);
                } else {

                    // Aggiungi il periodo corrente all'array dei periodi
                    periodi.push(obj);

                    // Crea un nuovo periodo corrente
                    obj = {
                        dataInizio: dataCorrente,
                        dataFine: dataCorrente,
                        meseInizio: getNomeMeseItaliano(dataCorrente.getMonth()),
                        numeroMeseInizio: dataCorrente.getMonth(),
                        meseFine: getNomeMeseItaliano(dataCorrente.getMonth()),
                        numeroMeseFine: dataCorrente.getMonth(),
                        lunghezza: 1,
                        carenza: 0,
                        meseMultiplo: false,
                        giorniMese1: 0,
                        giorniMese2: 0
                    };
                    periodi.push(obj);
                }
            }
        }

        let newPeriodi = []
        periodi.map(el => {
            if (el.meseMultiplo) {
                el.carenza = el.lunghezza <= 3 ? el.lunghezza : 3
                el.giorniMese1 = giorniPrimoMese(el.dataInizio, el.dataFine)
                if (el.carenza > el.giorniMese1) {
                    let newObjSplit = JSON.parse(JSON.stringify(el));
                    let diff = el.carenza - el.giorniMese1
                    let carenzaMese1 = el.carenza - diff
                    el.carenza = carenzaMese1
                    el.meseFine = el.meseInizio
                    el.numeroMeseFine = el.numeroMeseInizio
                    if (el.meseInizio != 'dicembre') {
                        newObjSplit.carenza = diff
                        newObjSplit.meseInizio = getNomeMeseItaliano(newObjSplit.numeroMeseInizio + 1)
                        newObjSplit.numeroMeseInizio = newObjSplit.numeroMeseInizio + 1
                        newPeriodi.push(newObjSplit)
                    }
                }
            } else {
                el.giorniMese1 = el.lunghezza
                el.carenza = el.lunghezza <= 3 ? el.lunghezza : 3
            }
        })
        periodi = periodi.concat(newPeriodi)

        return periodi;
    }


    // Funzione ausiliaria per verificare se due date sono consecutive
    const isDataConsecutiva = (dataCorrente, dataFine) => {
        let data = new Date(dataCorrente)
        let dataDopo = addDays(new Date(dataFine), 1)
        return data.getDay() === dataDopo.getDay() && data.getMonth() === dataDopo.getMonth() && data.getFullYear() === dataDopo.getFullYear()
    }

    // Funzione ausiliaria per ottenere il nome del mese in italiano
    const getNomeMeseItaliano = (mese) => {
        var mesiItaliano = [
            "gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno",
            "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"
        ];
        return mesiItaliano[mese];
    }

    // Funzione ausiliaria per ottenere il numero di giorni in un mese
    const giorniPrimoMese = (dataInizio, dataFine) => {
        const inizio = new Date(dataInizio);
        const meseInizio = inizio.getMonth();
        const annoInizio = inizio.getFullYear();
        const giorniInizio = new Date(annoInizio, meseInizio + 1, 0).getDate();
        const giorniPrimoMese = giorniInizio - inizio.getDate() + 1;
        return giorniPrimoMese;
    }



    const aggiungiTotale = (elementi, campo, label) => {
        const tot_gennaio = elementi.reduce(
            (accumulator, currentValue) => accumulator + currentValue[campo + "_gennaio"], 0
        );
        const tot_febbraio = elementi.reduce(
            (accumulator, currentValue) => accumulator + currentValue[campo + "_febbraio"], 0
        );
        const tot_marzo = elementi.reduce(
            (accumulator, currentValue) => accumulator + currentValue[campo + "_marzo"], 0
        );
        const tot_aprile = elementi.reduce(
            (accumulator, currentValue) => accumulator + currentValue[campo + "_aprile"], 0
        );
        const tot_maggio = elementi.reduce(
            (accumulator, currentValue) => accumulator + currentValue[campo + "_maggio"], 0
        );
        const tot_giugno = elementi.reduce(
            (accumulator, currentValue) => accumulator + currentValue[campo + "_giugno"], 0
        );
        const tot_luglio = elementi.reduce(
            (accumulator, currentValue) => accumulator + currentValue[campo + "_luglio"], 0
        );
        const tot_agosto = elementi.reduce(
            (accumulator, currentValue) => accumulator + currentValue[campo + "_agosto"], 0
        );
        const tot_settembre = elementi.reduce(
            (accumulator, currentValue) => accumulator + currentValue[campo + "_settembre"], 0
        );
        const tot_ottobre = elementi.reduce(
            (accumulator, currentValue) => accumulator + currentValue[campo + "_ottobre"], 0
        );
        const tot_novembre = elementi.reduce(
            (accumulator, currentValue) => accumulator + currentValue[campo + "_novembre"], 0
        );
        const tot_dicembre = elementi.reduce(
            (accumulator, currentValue) => accumulator + currentValue[campo + "_dicembre"], 0
        );

        let obj = {
            dipendente: label,

            gennaio_label: "",
            car_gennaio: 0,
            m_gennaio: 0,
            i_gennaio: 0,
            ph_gennaio: 0,
            avis_gennaio: 0,
            mat_gennaio: 0,
            mat_fac_gennaio: 0,
            cong_bien_gennaio: 0,
            perm_sind_gennaio: 0,
            cong_obbl_papa_gennaio: 0,
            malat_osp_gennaio: 0,

            febbraio_label: "",
            car_febbraio: 0,
            m_febbraio: 0,
            i_febbraio: 0,
            ph_febbraio: 0,
            avis_febbraio: 0,
            mat_febbraio: 0,
            mat_fac_febbraio: 0,
            cong_bien_febbraio: 0,
            perm_sind_febbraio: 0,
            cong_obbl_papa_febbraio: 0,
            malat_osp_febbraio: 0,

            marzo_label: "",
            car_marzo: 0,
            m_marzo: 0,
            i_marzo: 0,
            ph_marzo: 0,
            avis_marzo: 0,
            mat_marzo: 0,
            mat_fac_marzo: 0,
            cong_bien_marzo: 0,
            perm_sind_marzo: 0,
            cong_obbl_papa_marzo: 0,
            malat_osp_marzo: 0,

            aprile_label: "",
            car_aprile: 0,
            m_aprile: 0,
            i_aprile: 0,
            ph_aprile: 0,
            avis_aprile: 0,
            mat_aprile: 0,
            mat_fac_aprile: 0,
            cong_bien_aprile: 0,
            perm_sind_aprile: 0,
            cong_obbl_papa_aprile: 0,
            malat_osp_aprile: 0,

            maggio_label: "",
            car_maggio: 0,
            m_maggio: 0,
            i_maggio: 0,
            ph_maggio: 0,
            avis_maggio: 0,
            mat_maggio: 0,
            mat_fac_maggio: 0,
            cong_bien_maggio: 0,
            perm_sind_maggio: 0,
            cong_obbl_papa_maggio: 0,
            malat_osp_maggio: 0,

            giugno_label: "",
            car_giugno: 0,
            m_giugno: 0,
            i_giugno: 0,
            ph_giugno: 0,
            avis_giugno: 0,
            mat_giugno: 0,
            mat_fac_giugno: 0,
            cong_bien_giugno: 0,
            perm_sind_giugno: 0,
            cong_obbl_papa_giugno: 0,
            malat_osp_giugno: 0,

            luglio_label: "",
            car_luglio: 0,
            m_luglio: 0,
            i_luglio: 0,
            ph_luglio: 0,
            avis_luglio: 0,
            mat_luglio: 0,
            mat_fac_luglio: 0,
            cong_bien_luglio: 0,
            perm_sind_luglio: 0,
            cong_obbl_papa_luglio: 0,
            malat_osp_luglio: 0,

            agosto_label: "",
            car_agosto: 0,
            m_agosto: 0,
            i_agosto: 0,
            ph_agosto: 0,
            avis_agosto: 0,
            mat_agosto: 0,
            mat_fac_agosto: 0,
            cong_bien_agosto: 0,
            perm_sind_agosto: 0,
            cong_obbl_papa_agosto: 0,
            malat_osp_agosto: 0,

            settembre_label: "",
            car_settembre: 0,
            m_settembre: 0,
            i_settembre: 0,
            ph_settembre: 0,
            avis_settembre: 0,
            mat_settembre: 0,
            mat_fac_settembre: 0,
            cong_bien_settembre: 0,
            perm_sind_settembre: 0,
            cong_obbl_papa_settembre: 0,
            malat_osp_settembre: 0,

            ottobre_label: "",
            car_ottobre: 0,
            m_ottobre: 0,
            i_ottobre: 0,
            ph_ottobre: 0,
            avis_ottobre: 0,
            mat_ottobre: 0,
            mat_fac_ottobre: 0,
            cong_bien_ottobre: 0,
            perm_sind_ottobre: 0,
            cong_obbl_papa_ottobre: 0,
            malat_osp_ottobre: 0,

            novembre_label: "",
            car_novembre: 0,
            m_novembre: 0,
            i_novembre: 0,
            ph_novembre: 0,
            avis_novembre: 0,
            mat_novembre: 0,
            mat_fac_novembre: 0,
            cong_bien_novembre: 0,
            perm_sind_novembre: 0,
            cong_obbl_papa_novembre: 0,
            malat_osp_novembre: 0,

            dicembre_label: "",
            car_dicembre: 0,
            m_dicembre: 0,
            i_dicembre: 0,
            ph_dicembre: 0,
            avis_dicembre: 0,
            mat_dicembre: 0,
            mat_fac_dicembre: 0,
            cong_bien_dicembre: 0,
            perm_sind_dicembre: 0,
            cong_obbl_papa_dicembre: 0,
            malat_osp_dicembre: 0,

            totali_label: "",
            car_totale: 0,
            m_totale: 0,
            i_totale: 0,
            ph_totale: 0,
            avis_totale: 0,
            mat_totale: 0,
            mat_fac_totale: 0,
            cong_bien_totale: 0,
            perm_sind_totale: 0,
            cong_obbl_papa_totale: 0,
            malat_osp_totale: 0
        }


        obj[campo + "_gennaio"] = tot_gennaio
        obj[campo + "_febbraio"] = tot_febbraio
        obj[campo + "_marzo"] = tot_marzo
        obj[campo + "_aprile"] = tot_aprile
        obj[campo + "_maggio"] = tot_maggio
        obj[campo + "_giugno"] = tot_giugno
        obj[campo + "_luglio"] = tot_luglio
        obj[campo + "_agosto"] = tot_agosto
        obj[campo + "_settembre"] = tot_settembre
        obj[campo + "_ottobre"] = tot_ottobre
        obj[campo + "_novembre"] = tot_novembre
        obj[campo + "_dicembre"] = tot_dicembre
        obj[campo + "_totale"] = tot_gennaio + tot_febbraio + tot_marzo + tot_aprile + tot_maggio + tot_giugno + tot_luglio + tot_agosto + tot_settembre + tot_ottobre + tot_novembre + tot_dicembre
        elementi.push(obj)
        return elementi
    }

    const aggiungiTotale2Campi = (elementi, campo1, campo2, label, campo3) => {
        let tot_gennaio = 0
        let tot_febbraio = 0
        let tot_marzo = 0
        let tot_aprile = 0
        let tot_maggio = 0
        let tot_giugno = 0
        let tot_luglio = 0
        let tot_agosto = 0
        let tot_settembre = 0
        let tot_ottobre = 0
        let tot_novembre = 0
        let tot_dicembre = 0
        let indexCampo1 = elementi.findIndex(el => el.dipendente == campo1)
        let indexCampo2 = elementi.findIndex(el => el.dipendente == campo2)
        if (indexCampo1 >= 0 && indexCampo2 >= 0) {
            tot_gennaio = elementi[indexCampo1].car_gennaio + elementi[indexCampo2].m_gennaio
            tot_febbraio = elementi[indexCampo1].car_febbraio + elementi[indexCampo2].m_febbraio
            tot_marzo = elementi[indexCampo1].car_marzo + elementi[indexCampo2].m_marzo
            tot_aprile = elementi[indexCampo1].car_aprile + elementi[indexCampo2].m_aprile
            tot_maggio = elementi[indexCampo1].car_maggio + elementi[indexCampo2].m_maggio
            tot_giugno = elementi[indexCampo1].car_giugno + elementi[indexCampo2].m_giugno
            tot_luglio = elementi[indexCampo1].car_luglio + elementi[indexCampo2].m_luglio
            tot_agosto = elementi[indexCampo1].car_agosto + elementi[indexCampo2].m_agosto
            tot_settembre = elementi[indexCampo1].car_settembre + elementi[indexCampo2].m_settembre
            tot_ottobre = elementi[indexCampo1].car_ottobre + elementi[indexCampo2].m_ottobre
            tot_novembre = elementi[indexCampo1].car_novembre + elementi[indexCampo2].m_novembre
            tot_dicembre = elementi[indexCampo1].car_dicembre + elementi[indexCampo2].m_dicembre

        }

        let obj = {
            dipendente: label,

            gennaio_label: "",
            car_gennaio: 0,
            m_gennaio: 0,
            i_gennaio: 0,
            ph_gennaio: 0,
            avis_gennaio: 0,
            mat_gennaio: 0,
            mat_fac_gennaio: 0,
            cong_bien_gennaio: 0,
            perm_sind_gennaio: 0,
            cong_obbl_papa_gennaio: 0,
            malat_osp_gennaio: 0,


            febbraio_label: "",
            car_febbraio: 0,
            m_febbraio: 0,
            i_febbraio: 0,
            ph_febbraio: 0,
            avis_febbraio: 0,
            mat_febbraio: 0,
            mat_fac_febbraio: 0,
            cong_bien_febbraio: 0,
            perm_sind_febbraio: 0,
            cong_obbl_papa_febbraio: 0,
            malat_osp_febbraio: 0,

            marzo_label: "",
            car_marzo: 0,
            m_marzo: 0,
            i_marzo: 0,
            ph_marzo: 0,
            avis_marzo: 0,
            mat_marzo: 0,
            mat_fac_marzo: 0,
            cong_bien_marzo: 0,
            perm_sind_marzo: 0,
            cong_obbl_papa_marzo: 0,
            malat_osp_marzo: 0,

            aprile_label: "",
            car_aprile: 0,
            m_aprile: 0,
            i_aprile: 0,
            ph_aprile: 0,
            avis_aprile: 0,
            mat_aprile: 0,
            mat_fac_aprile: 0,
            cong_bien_aprile: 0,
            perm_sind_aprile: 0,
            cong_obbl_papa_aprile: 0,
            malat_osp_aprile: 0,

            maggio_label: "",
            car_maggio: 0,
            m_maggio: 0,
            i_maggio: 0,
            ph_maggio: 0,
            avis_maggio: 0,
            mat_maggio: 0,
            mat_fac_maggio: 0,
            cong_bien_maggio: 0,
            perm_sind_maggio: 0,
            cong_obbl_papa_maggio: 0,
            malat_osp_maggio: 0,

            giugno_label: "",
            car_giugno: 0,
            m_giugno: 0,
            i_giugno: 0,
            ph_giugno: 0,
            avis_giugno: 0,
            mat_giugno: 0,
            mat_fac_giugno: 0,
            cong_bien_giugno: 0,
            perm_sind_giugno: 0,
            cong_obbl_papa_giugno: 0,
            malat_osp_giugno: 0,

            luglio_label: "",
            car_luglio: 0,
            m_luglio: 0,
            i_luglio: 0,
            ph_luglio: 0,
            avis_luglio: 0,
            mat_luglio: 0,
            mat_fac_luglio: 0,
            cong_bien_luglio: 0,
            perm_sind_luglio: 0,
            cong_obbl_papa_luglio: 0,
            malat_osp_luglio: 0,
            
            agosto_label: "",
            car_agosto: 0,
            m_agosto: 0,
            i_agosto: 0,
            ph_agosto: 0,
            avis_agosto: 0,
            mat_agosto: 0,
            mat_fac_agosto: 0,
            cong_bien_agosto: 0,
            perm_sind_agosto: 0,
            cong_obbl_papa_agosto: 0,
            malat_osp_agosto: 0,

            settembre_label: "",
            car_settembre: 0,
            m_settembre: 0,
            i_settembre: 0,
            ph_settembre: 0,
            avis_settembre: 0,
            mat_settembre: 0,
            mat_fac_settembre: 0,
            cong_bien_settembre: 0,
            perm_sind_settembre: 0,
            cong_obbl_papa_settembre: 0,
            malat_osp_settembre: 0,

            ottobre_label: "",
            car_ottobre: 0,
            m_ottobre: 0,
            i_ottobre: 0,
            ph_ottobre: 0,
            avis_ottobre: 0,
            mat_ottobre: 0,
            mat_fac_ottobre: 0,
            cong_bien_ottobre: 0,
            perm_sind_ottobre: 0,
            cong_obbl_papa_ottobre: 0,
            malat_osp_ottobre: 0,

            novembre_label: "",
            car_novembre: 0,
            m_novembre: 0,
            i_novembre: 0,
            ph_novembre: 0,
            avis_novembre: 0,
            mat_novembre: 0,
            mat_fac_novembre: 0,
            cong_bien_novembre: 0,
            perm_sind_novembre: 0,
            cong_obbl_papa_novembre: 0,
            malat_osp_novembre: 0,

            dicembre_label: "",
            car_dicembre: 0,
            m_dicembre: 0,
            i_dicembre: 0,
            ph_dicembre: 0,
            avis_dicembre: 0,
            mat_dicembre: 0,
            mat_fac_dicembre: 0,
            cong_bien_dicembre: 0,
            perm_sind_dicembre: 0,
            cong_obbl_papa_dicembre: 0,
            malat_osp_dicembre: 0,

            totali_label: "",
            car_totale: 0,
            m_totale: 0,
            i_totale: 0,
            ph_totale: 0,
            avis_totale: 0,
            mat_totale: 0,
            mat_fac_totale: 0,
            cong_bien_totale: 0,
            perm_sind_totale: 0,
            cong_obbl_papa_totale: 0,
            malat_osp_totale: 0
        }


        obj[campo3 + "_gennaio"] = tot_gennaio
        obj[campo3 + "_febbraio"] = tot_febbraio
        obj[campo3 + "_marzo"] = tot_marzo
        obj[campo3 + "_aprile"] = tot_aprile
        obj[campo3 + "_maggio"] = tot_maggio
        obj[campo3 + "_giugno"] = tot_giugno
        obj[campo3 + "_luglio"] = tot_luglio
        obj[campo3 + "_agosto"] = tot_agosto
        obj[campo3 + "_settembre"] = tot_settembre
        obj[campo3 + "_ottobre"] = tot_ottobre
        obj[campo3 + "_novembre"] = tot_novembre
        obj[campo3 + "_dicembre"] = tot_dicembre
        obj[campo3 + "_totale"] = tot_gennaio + tot_febbraio + tot_marzo + tot_aprile + tot_maggio + tot_giugno + tot_luglio + tot_agosto + tot_settembre + tot_ottobre + tot_novembre + tot_dicembre
        elementi.push(obj)
        return elementi
    }

    const generaStrutturaExcel = (elementi) => {
        let array = []
        elementi.map(el => {
            let obj = {
                dipendente: el.dipendente.cognome + " " + el.dipendente.nome,

                gennaio_label: "",
                car_gennaio: el.mesi.gennaio.carenza,
                m_gennaio: el.mesi.gennaio.MA.length - el.mesi.gennaio.carenza,
                i_gennaio: el.mesi.gennaio.IN.length,
                ph_gennaio: el.mesi.gennaio.PH.length,
                avis_gennaio: el.mesi.gennaio.DS.length,
                mat_gennaio: el.mesi.gennaio.MT.length,
                mat_fac_gennaio: el.mesi.gennaio.MF.length,
                cong_bien_gennaio: el.mesi.gennaio.CD.length,
                perm_sind_gennaio: el.mesi.gennaio.PS.length,
                cong_obbl_papa_gennaio: el.mesi.gennaio.CP.length,
                malat_osp_gennaio: el.mesi.gennaio.MO.length,


                febbraio_label: "",
                car_febbraio: el.mesi.febbraio.carenza,
                m_febbraio: el.mesi.febbraio.MA.length - el.mesi.febbraio.carenza,
                i_febbraio: el.mesi.febbraio.IN.length,
                ph_febbraio: el.mesi.febbraio.PH.length,
                avis_febbraio: el.mesi.febbraio.DS.length,
                mat_febbraio: el.mesi.febbraio.MT.length,
                mat_fac_febbraio: el.mesi.febbraio.MF.length,
                cong_bien_febbraio: el.mesi.febbraio.CD.length,
                perm_sind_febbraio: el.mesi.febbraio.PS.length,
                cong_obbl_papa_febbraio: el.mesi.febbraio.CP.length,
                malat_osp_febbraio: el.mesi.febbraio.MO.length,

                marzo_label: "",
                car_marzo: el.mesi.marzo.carenza,
                m_marzo: el.mesi.marzo.MA.length - el.mesi.marzo.carenza,
                i_marzo: el.mesi.marzo.IN.length,
                ph_marzo: el.mesi.marzo.PH.length,
                avis_marzo: el.mesi.marzo.DS.length,
                mat_marzo: el.mesi.marzo.MT.length,
                mat_fac_marzo: el.mesi.marzo.MF.length,
                cong_bien_marzo: el.mesi.marzo.CD.length,
                perm_sind_marzo: el.mesi.marzo.PS.length,
                cong_obbl_papa_marzo: el.mesi.marzo.CP.length,
                malat_osp_marzo: el.mesi.marzo.MO.length,

                aprile_label: "",
                car_aprile: el.mesi.aprile.carenza,
                m_aprile: el.mesi.aprile.MA.length - el.mesi.aprile.carenza,
                i_aprile: el.mesi.aprile.IN.length,
                ph_aprile: el.mesi.aprile.PH.length,
                avis_aprile: el.mesi.aprile.DS.length,
                mat_aprile: el.mesi.aprile.MT.length,
                mat_fac_aprile: el.mesi.aprile.MF.length,
                cong_bien_aprile: el.mesi.aprile.CD.length,
                perm_sind_aprile: el.mesi.aprile.PS.length,
                cong_obbl_papa_aprile: el.mesi.aprile.CP.length,
                malat_osp_aprile: el.mesi.aprile.MO.length,

                maggio_label: "",
                car_maggio: el.mesi.maggio.carenza,
                m_maggio: el.mesi.maggio.MA.length - el.mesi.maggio.carenza,
                i_maggio: el.mesi.maggio.IN.length,
                ph_maggio: el.mesi.maggio.PH.length,
                avis_maggio: el.mesi.maggio.DS.length,
                mat_maggio: el.mesi.maggio.MT.length,
                mat_fac_maggio: el.mesi.maggio.MF.length,
                cong_bien_maggio: el.mesi.maggio.CD.length,
                perm_sind_maggio: el.mesi.maggio.PS.length,
                cong_obbl_papa_maggio: el.mesi.maggio.CP.length,
                malat_osp_maggio: el.mesi.maggio.MO.length,

                giugno_label: "",
                car_giugno: el.mesi.giugno.carenza,
                m_giugno: el.mesi.giugno.MA.length - el.mesi.giugno.carenza,
                i_giugno: el.mesi.giugno.IN.length,
                ph_giugno: el.mesi.giugno.PH.length,
                avis_giugno: el.mesi.giugno.DS.length,
                mat_giugno: el.mesi.giugno.MT.length,
                mat_fac_giugno: el.mesi.giugno.MF.length,
                cong_bien_giugno: el.mesi.giugno.CD.length,
                perm_sind_giugno: el.mesi.giugno.PS.length,
                cong_obbl_papa_giugno: el.mesi.giugno.CP.length,
                malat_osp_giugno: el.mesi.giugno.MO.length,

                luglio_label: "",
                car_luglio: el.mesi.luglio.carenza,
                m_luglio: el.mesi.luglio.MA.length - el.mesi.luglio.carenza,
                i_luglio: el.mesi.luglio.IN.length,
                ph_luglio: el.mesi.luglio.PH.length,
                avis_luglio: el.mesi.luglio.DS.length,
                mat_luglio: el.mesi.luglio.MT.length,
                mat_fac_luglio: el.mesi.luglio.MF.length,
                cong_bien_luglio: el.mesi.luglio.CD.length,
                perm_sind_luglio: el.mesi.luglio.PS.length,
                cong_obbl_papa_luglio: el.mesi.luglio.CP.length,
                malat_osp_luglio: el.mesi.luglio.MO.length,

                agosto_label: "",
                car_agosto: el.mesi.agosto.carenza,
                m_agosto: el.mesi.agosto.MA.length - el.mesi.agosto.carenza,
                i_agosto: el.mesi.agosto.IN.length,
                ph_agosto: el.mesi.agosto.PH.length,
                avis_agosto: el.mesi.agosto.DS.length,
                mat_agosto: el.mesi.agosto.MT.length,
                mat_fac_agosto: el.mesi.agosto.MF.length,
                cong_bien_agosto: el.mesi.agosto.CD.length,
                perm_sind_agosto: el.mesi.agosto.PS.length,
                cong_obbl_papa_agosto: el.mesi.agosto.CP.length,
                malat_osp_agosto: el.mesi.agosto.MO.length,

                settembre_label: "",
                car_settembre: el.mesi.settembre.carenza,
                m_settembre: el.mesi.settembre.MA.length - el.mesi.settembre.carenza,
                i_settembre: el.mesi.settembre.IN.length,
                ph_settembre: el.mesi.settembre.PH.length,
                avis_settembre: el.mesi.settembre.DS.length,
                mat_settembre: el.mesi.settembre.MT.length,
                mat_fac_settembre: el.mesi.settembre.MF.length,
                cong_bien_settembre: el.mesi.settembre.CD.length,
                perm_sind_settembre: el.mesi.settembre.PS.length,
                cong_obbl_papa_settembre: el.mesi.settembre.CP.length,
                malat_osp_settembre: el.mesi.settembre.MO.length,

                ottobre_label: "",
                car_ottobre: el.mesi.ottobre.carenza,
                m_ottobre: el.mesi.ottobre.MA.length - el.mesi.ottobre.carenza,
                i_ottobre: el.mesi.ottobre.IN.length,
                ph_ottobre: el.mesi.ottobre.PH.length,
                avis_ottobre: el.mesi.ottobre.DS.length,
                mat_ottobre: el.mesi.ottobre.MT.length,
                mat_fac_ottobre: el.mesi.ottobre.MF.length,
                cong_bien_ottobre: el.mesi.ottobre.CD.length,
                perm_sind_ottobre: el.mesi.ottobre.PS.length,
                cong_obbl_papa_ottobre: el.mesi.ottobre.CP.length,
                malat_osp_ottobre: el.mesi.ottobre.MO.length,

                novembre_label: "",
                car_novembre: el.mesi.novembre.carenza,
                m_novembre: el.mesi.novembre.MA.length - el.mesi.novembre.carenza,
                i_novembre: el.mesi.novembre.IN.length,
                ph_novembre: el.mesi.novembre.PH.length,
                avis_novembre: el.mesi.novembre.DS.length,
                mat_novembre: el.mesi.novembre.MT.length,
                mat_fac_novembre: el.mesi.novembre.MF.length,
                cong_bien_novembre: el.mesi.novembre.CD.length,
                perm_sind_novembre: el.mesi.novembre.PS.length,
                cong_obbl_papa_novembre: el.mesi.novembre.CP.length,
                malat_osp_novembre: el.mesi.novembre.MO.length,

                dicembre_label: "",
                car_dicembre: el.mesi.dicembre.carenza,
                m_dicembre: el.mesi.dicembre.MA.length - el.mesi.dicembre.carenza,
                i_dicembre: el.mesi.dicembre.IN.length,
                ph_dicembre: el.mesi.dicembre.PH.length,
                avis_dicembre: el.mesi.dicembre.DS.length,
                mat_dicembre: el.mesi.dicembre.MT.length,
                mat_fac_dicembre: el.mesi.dicembre.MF.length,
                cong_bien_dicembre: el.mesi.dicembre.CD.length,
                perm_sind_dicembre: el.mesi.dicembre.PS.length,
                cong_obbl_papa_dicembre: el.mesi.dicembre.CP.length,
                malat_osp_dicembre: el.mesi.dicembre.MO.length,

                totali_label: "",
                car_totale: 0,
                m_totale: 0,
                i_totale: 0,
                ph_totale: 0,
                avis_totale: 0,
                mat_totale: 0,
                mat_fac_totale: 0,
                cong_bien_totale: 0,
                perm_sind_totale: 0,
                cong_obbl_papa_totale: 0,
                malat_osp_totale: 0

            }
            obj.car_totale = obj.car_gennaio + obj.car_febbraio + obj.car_marzo + obj.car_aprile + obj.car_maggio + obj.car_giugno + obj.car_luglio + obj.car_agosto + obj.car_settembre + obj.car_ottobre + obj.car_novembre + obj.car_dicembre
            obj.m_totale = obj.m_gennaio + obj.m_febbraio + obj.m_marzo + obj.m_aprile + obj.m_maggio + obj.m_giugno + obj.m_luglio + obj.m_agosto + obj.m_settembre + obj.m_ottobre + obj.m_novembre + obj.m_dicembre
            obj.i_totale = obj.i_gennaio + obj.i_febbraio + obj.i_marzo + obj.i_aprile + obj.i_maggio + obj.i_giugno + obj.i_luglio + obj.i_agosto + obj.i_settembre + obj.i_ottobre + obj.i_novembre + obj.i_dicembre
            obj.ph_totale = obj.ph_gennaio + obj.ph_febbraio + obj.ph_marzo + obj.ph_aprile + obj.ph_maggio + obj.ph_giugno + obj.ph_luglio + obj.ph_agosto + obj.ph_settembre + obj.ph_ottobre + obj.ph_novembre + obj.ph_dicembre
            obj.avis_totale = obj.avis_gennaio + obj.avis_febbraio + obj.avis_marzo + obj.avis_aprile + obj.avis_maggio + obj.avis_giugno + obj.avis_luglio + obj.avis_agosto + obj.avis_settembre + obj.avis_ottobre + obj.avis_novembre + obj.avis_dicembre
            obj.mat_totale = obj.mat_gennaio + obj.mat_febbraio + obj.mat_marzo + obj.mat_aprile + obj.mat_maggio + obj.mat_giugno + obj.mat_luglio + obj.mat_agosto + obj.mat_settembre + obj.mat_ottobre + obj.mat_novembre + obj.mat_dicembre
            obj.mat_fac_totale = obj.mat_fac_gennaio + obj.mat_fac_febbraio + obj.mat_fac_marzo + obj.mat_fac_aprile + obj.mat_fac_maggio + obj.mat_fac_giugno + obj.mat_fac_luglio + obj.mat_fac_agosto + obj.mat_fac_settembre + obj.mat_fac_ottobre + obj.mat_fac_novembre + obj.mat_fac_dicembre
            obj.cong_bien_totale = obj.cong_bien_gennaio + obj.cong_bien_febbraio + obj.cong_bien_marzo + obj.cong_bien_aprile + obj.cong_bien_maggio + obj.cong_bien_giugno + obj.cong_bien_luglio + obj.cong_bien_agosto + obj.cong_bien_settembre + obj.cong_bien_ottobre + obj.cong_bien_novembre + obj.cong_bien_dicembre
            obj.perm_sind_totale = obj.perm_sind_gennaio + obj.perm_sind_febbraio + obj.perm_sind_marzo + obj.perm_sind_aprile + obj.perm_sind_maggio + obj.perm_sind_giugno + obj.perm_sind_luglio + obj.perm_sind_agosto + obj.perm_sind_settembre + obj.perm_sind_ottobre + obj.perm_sind_novembre + obj.perm_sind_dicembre
            obj.cong_obbl_papa_totale = obj.cong_obbl_papa_gennaio + obj.cong_obbl_papa_febbraio + obj.cong_obbl_papa_marzo + obj.cong_obbl_papa_aprile + obj.cong_obbl_papa_maggio + obj.cong_obbl_papa_giugno + obj.cong_obbl_papa_luglio + obj.cong_obbl_papa_agosto + obj.cong_obbl_papa_settembre + obj.cong_obbl_papa_ottobre + obj.cong_obbl_papa_novembre + obj.cong_obbl_papa_dicembre
            obj.malat_osp_totale = obj.malat_osp_gennaio + obj.malat_osp_febbraio + obj.malat_osp_marzo + obj.malat_osp_aprile + obj.malat_osp_maggio + obj.malat_osp_giugno + obj.malat_osp_luglio + obj.malat_osp_agosto + obj.malat_osp_settembre + obj.malat_osp_ottobre + obj.malat_osp_novembre + obj.malat_osp_dicembre

            array.push(obj)
        })
        return array
    }

    const calcoloEccezione = ({ dipendenti, eccezione }) => {
        dipendenti.map(dipendente => {
            for (var mese in dipendente.mesi) {
                dipendente.mesi[mese].elementi.map(elemento => {
                    if (elemento.disponibilita == eccezione) {
                        if (eccezione in dipendente.mesi[mese]) {
                            dipendente.mesi[mese][eccezione].push(elemento)
                        }
                    }
                })
            }
        })
        return dipendenti
    }

    const getNomeMese = (mese) => {
        switch (mese) {
            case 0:
                return "gennaio"
            case 1:
                return "febbraio"
            case 2:
                return "marzo"
            case 3:
                return "aprile"
            case 4:
                return "maggio"
            case 5:
                return "giugno"
            case 6:
                return "luglio"
            case 7:
                return "agosto"
            case 8:
                return "settembre"
            case 9:
                return "ottobre"
            case 10:
                return "novembre"
            case 11:
                return "dicembre"
            default:
                return "gennaio"
        }

    }

   

    //FUNZIONI ONCLICK

    const onClickGeneraAssenteismo = () => {
        getGiorniEccezioneAssenteismo({ dataInizio: dataInizioInput, dataFine: dataFineInput, idFiliale: filtraFiliale })
        setLoadingRichieste(true)
    }
    //FUNZIONI ONCHANGE

    //*
    const onChangeRangeDateInizio = (data) => {
        setDataInizioInput(data)
        setDataFineInput(setHours(setMinutes(endOfYear(data), 59), 23))
    }

    //*
    const onChangeRangeDateFine = (data) => {
        setDataFineInput(data)
    }

    const onChangeFiltraDipendentiPerFiliale = i => {
        setFiltraFiliale(filialiList[i]._id)
    };

    const generaMese = (giorno) => {
        switch(giorno) {
            case 0:
            return 'gennaio'
            case 1:
            return 'febbraio'
            case 2:
            return 'marzo'
            case 3:
            return 'aprile'
            case 4:
            return 'maggio'
            case 5:
            return 'giugno'
            case 6:
            return 'luglio'
            case 7:
            return 'agosto'
            case 8:
            return 'settembre'
            case 9:
            return 'ottobre'
            case 10:
            return 'novembre'
            case 11:
            return 'dicembre'
            default: return 'gennaio'
        }
    }


    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        const headerLabel = header.map(function (row) { return row.label })
        sheetData.unshift(headerLabel);
        return sheetData;
    }

    
    const sommaValoriColonna = (workbook, sheet, colonna, inizio, fine) => {
        let somma = 0;
        for(var i=inizio; i<fine; i++){
            somma = somma + Number(workbook.sheet(sheet).cell(colonna+i).value())
        }
        return somma
    }

    const MediaValoriColonna = (workbook, sheet, colonna, inizio, fine, meseDivisore) => {
        let somma = 0;
        for(var i=inizio; i<fine; i++){
            somma = somma + Number(workbook.sheet(sheet).cell(colonna+i).value())
        }
        return (somma/meseDivisore).toFixed(2)
    }

    async function saveAsExceOLDl(data, headerA, headerB, arrayDipendenti, meseDivisore) {
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {

            //FOGLIO 1

            const sheet1 = workbook.addSheet('Dettaglio', 1);
            workbook.deleteSheet(0);
            const sheetData = getSheetData(data, headerA);
            const totalColumns = sheetData[0].length;
            const numeroRighe = data.length + 1
            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            //sheet1.column("A").style("fill", "b6d7a8");
            sheet1.range("A1:" + "A" + numeroRighe).style("fill", "b6d7a8");
            sheet1.range("B1:" + "K" + numeroRighe).style("fill", "eeeeee");
            sheet1.range("L1:" + "U" + numeroRighe).style("fill", "d2d2d2");
            sheet1.range("V1:" + "AE" + numeroRighe).style("fill", "eeeeee");
            sheet1.range("AF1:" + "AO" + numeroRighe).style("fill", "d2d2d2");
            sheet1.range("AP1:" + "AY" + numeroRighe).style("fill", "eeeeee");
            sheet1.range("AZ1:" + "BI" + numeroRighe).style("fill", "d2d2d2");
            sheet1.range("BJ1:" + "BS" + numeroRighe).style("fill", "eeeeee");
            sheet1.range("BT1:" + "CC" + numeroRighe).style("fill", "d2d2d2");
            sheet1.range("CD1:" + "CM" + numeroRighe).style("fill", "eeeeee");
            sheet1.range("CN1:" + "CW" + numeroRighe).style("fill", "d2d2d2");
            sheet1.range("CX1:" + "DG" + numeroRighe).style("fill", "eeeeee");
            sheet1.range("DH1:" + "DQ" + numeroRighe).style("fill", "d2d2d2");
            sheet1.range("DR1:" + "EA" + numeroRighe).style("fill", "f4cccc");
            //sheet1.range("A"+(numeroRighe-8)+":" + "DE"+(numeroRighe)).style("fill", "cfe2f3");
            range.style("border", "thin");
            sheet1.range("DR1:" + "EA" + numeroRighe).style("borderStyle", "medium");
            sheet1.range("DR1:" + "EA" + numeroRighe).style("bold", true);
            sheet1.range("A" + (numeroRighe - 9) + ":" + "DQ" + (numeroRighe)).style("borderStyle", "medium");
            sheet1.range("A" + (numeroRighe - 9) + ":" + "DN" + (numeroRighe)).style("bold", true);
            


            return workbook.outputAsync().then((res) => {
                saveAs(res, "assenteismo-A.xlsx");
                
                //FOGLIO 2
                
                const sheet2 = workbook.addSheet('Riepilogo', 1);
                workbook.deleteSheet(0);
                sheet2.cell("B9").value("gg");
                sheet2.cell("C9").value("n° dip");
                sheet2.cell("D9").value("tot");
                sheet2.cell("E9").value("car");
                sheet2.cell("F9").value("INPS");
                sheet2.cell("G9").value("tot");
                sheet2.cell("H9").value("INAIL");
                sheet2.cell("I9").value("tot");
                sheet2.cell("J9").value("c.b.");
                sheet2.cell("K9").value("tot");
                sheet2.cell("L9").value("104");
                sheet2.cell("M9").value("tot");
                sheet2.cell("N9").value("avis");
                sheet2.cell("O9").value("tot");
                sheet2.cell("P9").value("mat");
                sheet2.cell("Q9").value("tot");
                sheet2.cell("R9").value("mat fac");
                sheet2.cell("S9").value("tot");
                sheet2.cell("T9").value("tot gg");
                sheet2.cell("U9").value("%Ass.");
                sheet2.cell("V9").value("tot ps");
                sheet2.cell("F10").value("gg");
                sheet2.cell("G10").value("%");
                sheet2.cell("H10").value("gg");
                sheet2.cell("I10").value("%");
                sheet2.cell("J10").value("gg");
                sheet2.cell("K10").value("%");
                sheet2.cell("L10").value("gg");
                sheet2.cell("M10").value("%");
                sheet2.cell("N10").value("gg");
                sheet2.cell("O10").value("%");
                sheet2.cell("P10").value("gg");
                sheet2.cell("Q10").value("%");
                sheet2.cell("R10").value("gg");
                sheet2.cell("S10").value("%");
                sheet2.cell("U10").value("%");
                sheet2.cell("A26").value("Tot. Malattia");
                sheet2.cell("A27").value("% carenza");
                sheet2.cell("A28").value("% inps");

                let year = dataInizioInput.getFullYear()
                let arrayGiorniMeseData = [startOfMonth(new Date(year, 0, 1, 12, 0, 0)), startOfMonth(new Date(year, 1, 1, 12, 0, 0)), startOfMonth(new Date(year, 2, 1, 12, 0, 0)), startOfMonth(new Date(year, 3, 1, 12, 0, 0)),startOfMonth(new Date(year, 4, 1, 12, 0, 0)), startOfMonth(new Date(year, 5, 1, 12, 0, 0)), startOfMonth(new Date(year, 6, 1, 12, 0, 0)), startOfMonth(new Date(year, 7, 1, 12, 0, 0)), startOfMonth(new Date(year, 8, 1, 12, 0, 0)), startOfMonth(new Date(year, 9, 1, 12, 0, 0)), startOfMonth(new Date(year, 10, 1, 12, 0, 0)), startOfMonth(new Date(year, 11, 1, 12, 0, 0))]
                let arrayGiorniMese = [arrayGiorniMeseData[0].getDate(), arrayGiorniMeseData[1].getDate(), arrayGiorniMeseData[2].getDate(), arrayGiorniMeseData[3].getDate(), arrayGiorniMeseData[4].getDate(), arrayGiorniMeseData[5].getDate(), arrayGiorniMeseData[6].getDate(), arrayGiorniMeseData[7].getDate(), arrayGiorniMeseData[8].getDate(), arrayGiorniMeseData[9].getDate(), arrayGiorniMeseData[10].getDate(), arrayGiorniMeseData[11].getDate()]
                let arrayDipendentiAttiviMese = []
                for(var i=11; i<23; i++){
                    sheet2.cell("A"+i).value(generaMese(i-11).toUpperCase());
                    sheet2.cell("B"+i).value(arrayGiorniMese[i-11]);
                    arrayDipendentiAttiviMese[i-11] = checkDipendentiAttivi(arrayDipendenti, arrayGiorniMeseData[i-11])
                    sheet2.cell("C"+i).value(arrayDipendentiAttiviMese[i-11]);
                    sheet2.cell("D"+i).value(arrayDipendentiAttiviMese[i-11] * arrayGiorniMese[i-11]);
                    const indexCarenza = data.findIndex(item => item.dipendente == "Tot. Carenza");
                    if(indexCarenza >= 0){
                        sheet2.cell("E"+i).value(data[indexCarenza]["car_"+generaMese(i-11)]);
                    }
                    const indexINPS = data.findIndex(item => item.dipendente == "Tot. inps");
                    if(indexINPS >= 0){
                        sheet2.cell("F"+i).value(data[indexINPS]["m_"+generaMese(i-11)]);
                        sheet2.cell("G"+i).value((((workbook.sheet("Riepilogo").cell("E"+i).value() + workbook.sheet("Riepilogo").cell("F"+i).value())/workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const indexINAIL = data.findIndex(item => item.dipendente == "Tot. inf");
                    if(indexINAIL >= 0){
                        sheet2.cell("H"+i).value(data[indexINAIL]["i_"+generaMese(i-11)]);
                        sheet2.cell("I"+i).value(((workbook.sheet("Riepilogo").cell("H"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const indexCB = data.findIndex(item => item.dipendente == "Tot. Cong. Bien.");
                    if(indexCB >= 0){
                        sheet2.cell("J"+i).value(data[indexCB]["cong_bien_"+generaMese(i-11)]);
                        sheet2.cell("K"+i).value(((workbook.sheet("Riepilogo").cell("J"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const index104 = data.findIndex(item => item.dipendente == "Tot. 104");
                    if(index104 >= 0){
                        sheet2.cell("L"+i).value(data[index104]["ph_"+generaMese(i-11)]);
                        sheet2.cell("M"+i).value(((workbook.sheet("Riepilogo").cell("L"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const indexAvis = data.findIndex(item => item.dipendente == "Tot. Avis");
                    if(indexAvis >= 0){
                        sheet2.cell("N"+i).value(data[indexAvis]["avis_"+generaMese(i-11)]);
                        sheet2.cell("O"+i).value(((workbook.sheet("Riepilogo").cell("N"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const indexMat = data.findIndex(item => item.dipendente == "Tot. Mat.");
                    if(indexMat >= 0){
                        sheet2.cell("P"+i).value(data[indexMat]["mat_"+generaMese(i-11)]);
                        sheet2.cell("Q"+i).value(((workbook.sheet("Riepilogo").cell("P"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const indexMatFac = data.findIndex(item => item.dipendente == "Tot. Mat.");
                    if(indexMatFac >= 0){
                        sheet2.cell("R"+i).value(data[indexMatFac]["mat_fac_"+generaMese(i-11)]);
                        sheet2.cell("S"+i).value(((workbook.sheet("Riepilogo").cell("R"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const indexPermSind = data.findIndex(item => item.dipendente == "Tot. Perm. Sind.");
                    if(indexMatFac >= 0){
                        sheet2.cell("V"+i).value(data[indexPermSind]["perm_sind_"+generaMese(i-11)]);
                    }

                    
                    sheet2.cell("T"+i).value(workbook.sheet("Riepilogo").cell("E"+i).value() + workbook.sheet("Riepilogo").cell("F"+i).value() + workbook.sheet("Riepilogo").cell("H"+i).value() + workbook.sheet("Riepilogo").cell("L"+i).value() + workbook.sheet("Riepilogo").cell("J"+i).value() + workbook.sheet("Riepilogo").cell("N"+i).value() + workbook.sheet("Riepilogo").cell("P"+i).value() + workbook.sheet("Riepilogo").cell("R"+i).value());
                    sheet2.cell("U"+i).value(((workbook.sheet("Riepilogo").cell("T"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));

                }
                sheet2.cell("A23").value("Totali");
                sheet2.cell("A24").value("Medie");
                sheet2.cell("B23").value(sommaValoriColonna(workbook, "Riepilogo", "B", 11, 23));
                sheet2.cell("D23").value(sommaValoriColonna(workbook, "Riepilogo", "D", 11, 23));
                sheet2.cell("E23").value(sommaValoriColonna(workbook, "Riepilogo", "E", 11, 23));
                sheet2.cell("E24").value(MediaValoriColonna(workbook, "Riepilogo", "E", 11, 23, meseDivisore));
                sheet2.cell("F23").value(sommaValoriColonna(workbook, "Riepilogo", "F", 11, 23));
                sheet2.cell("F24").value(MediaValoriColonna(workbook, "Riepilogo", "F", 11, 23, meseDivisore));
                sheet2.cell("G23").value(sommaValoriColonna(workbook, "Riepilogo", "G", 11, 23));
                sheet2.cell("G24").value(MediaValoriColonna(workbook, "Riepilogo", "G", 11, 23, meseDivisore));
                sheet2.cell("H23").value(sommaValoriColonna(workbook, "Riepilogo", "H", 11, 23));
                sheet2.cell("H24").value(MediaValoriColonna(workbook, "Riepilogo", "H", 11, 23, meseDivisore));
                sheet2.cell("I23").value(sommaValoriColonna(workbook, "Riepilogo", "I", 11, 23));
                sheet2.cell("I24").value(MediaValoriColonna(workbook, "Riepilogo", "I", 11, 23, meseDivisore));
                sheet2.cell("J23").value(sommaValoriColonna(workbook, "Riepilogo", "J", 11, 23));
                sheet2.cell("J24").value(MediaValoriColonna(workbook, "Riepilogo", "J", 11, 23, meseDivisore));
                sheet2.cell("K23").value(sommaValoriColonna(workbook, "Riepilogo", "K", 11, 23));
                sheet2.cell("K24").value(MediaValoriColonna(workbook, "Riepilogo", "K", 11, 23, meseDivisore));
                sheet2.cell("L23").value(sommaValoriColonna(workbook, "Riepilogo", "L", 11, 23));
                sheet2.cell("L24").value(MediaValoriColonna(workbook, "Riepilogo", "L", 11, 23, meseDivisore));
                sheet2.cell("M23").value(sommaValoriColonna(workbook, "Riepilogo", "M", 11, 23));
                sheet2.cell("M24").value(MediaValoriColonna(workbook, "Riepilogo", "M", 11, 23, meseDivisore));
                sheet2.cell("N23").value(sommaValoriColonna(workbook, "Riepilogo", "N", 11, 23));
                sheet2.cell("N24").value(MediaValoriColonna(workbook, "Riepilogo", "N", 11, 23, meseDivisore));
                sheet2.cell("O23").value(sommaValoriColonna(workbook, "Riepilogo", "O", 11, 23));
                sheet2.cell("O24").value(MediaValoriColonna(workbook, "Riepilogo", "O", 11, 23, meseDivisore));
                sheet2.cell("P23").value(sommaValoriColonna(workbook, "Riepilogo", "P", 11, 23));
                sheet2.cell("P24").value(MediaValoriColonna(workbook, "Riepilogo", "P", 11, 23, meseDivisore));
                sheet2.cell("Q23").value(sommaValoriColonna(workbook, "Riepilogo", "Q", 11, 23));
                sheet2.cell("Q24").value(MediaValoriColonna(workbook, "Riepilogo", "Q", 11, 23, meseDivisore));
                sheet2.cell("R23").value(sommaValoriColonna(workbook, "Riepilogo", "R", 11, 23));
                sheet2.cell("R24").value(MediaValoriColonna(workbook, "Riepilogo", "R", 11, 23, meseDivisore));
                sheet2.cell("S23").value(sommaValoriColonna(workbook, "Riepilogo", "S", 11, 23));
                sheet2.cell("S24").value(MediaValoriColonna(workbook, "Riepilogo", "S", 11, 23, meseDivisore));
                sheet2.cell("T23").value(sommaValoriColonna(workbook, "Riepilogo", "T", 11, 23));
                sheet2.cell("T24").value(MediaValoriColonna(workbook, "Riepilogo", "T", 11, 23, meseDivisore));
                sheet2.cell("U23").value(sommaValoriColonna(workbook, "Riepilogo", "U", 11, 23));
                sheet2.cell("U24").value(MediaValoriColonna(workbook, "Riepilogo", "U", 11, 23, meseDivisore));
                sheet2.cell("V23").value(sommaValoriColonna(workbook, "Riepilogo", "V", 11, 23));
                sheet2.cell("V24").value(MediaValoriColonna(workbook, "Riepilogo", "V", 11, 23, meseDivisore));

                
                
                sheet2.cell("B26").value(((workbook.sheet("Riepilogo").cell("E23").value() + workbook.sheet("Riepilogo").cell("F23").value())).toFixed(2));
                sheet2.cell("B27").value(((workbook.sheet("Riepilogo").cell("E23").value() / workbook.sheet("Riepilogo").cell("B26").value())*100).toFixed(2));
                sheet2.cell("B28").value(((workbook.sheet("Riepilogo").cell("F23").value() / workbook.sheet("Riepilogo").cell("B26").value())*100).toFixed(2));


                sheet2.range("B9:V9").style("borderStyle", "medium");
                sheet2.range("A11:V24").style("borderStyle", "medium");
                sheet2.range("B9:V9").style("bold", true);
                sheet2.range("A23:V23").style("bold", true);
                sheet2.range("A24:V24").style("bold", true);
                sheet2.range("A25:A27").style("bold", true);
                sheet2.range("A11:A22").style("bold", true);
                //sheet2.range("E24:U24").style("fill", "ffa500");

                return workbook.outputAsync().then((res) => {
                    saveAs(res, "assenteismo-B.xlsx");
                    setLoadingRichieste(false)
                });
            });
        });
    }

    async function saveAsExcel(data, headerA, headerB, arrayDipendenti, meseDivisore) {
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {

            //FOGLIO 1

            const sheet1 = workbook.addSheet('Dettaglio', 0);
            const sheetData = getSheetData(data, headerA);
            const numeroRighe = data.length + 1
            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + "A" + numeroRighe).style("fill", "b6d7a8");
            sheet1.range("B1:" + "M" + numeroRighe).style("fill", "eeeeee");
            sheet1.range("N1:" + "Y" + numeroRighe).style("fill", "d2d2d2");
            sheet1.range("Z1:" + "AK" + numeroRighe).style("fill", "eeeeee");
            sheet1.range("AL1:" + "AW" + numeroRighe).style("fill", "d2d2d2");
            sheet1.range("AX1:" + "BI" + numeroRighe).style("fill", "eeeeee");
            sheet1.range("BJ1:" + "BU" + numeroRighe).style("fill", "d2d2d2");
            sheet1.range("BV1:" + "CG" + numeroRighe).style("fill", "eeeeee");
            sheet1.range("CH1:" + "CS" + numeroRighe).style("fill", "d2d2d2");
            sheet1.range("CT1:" + "DE" + numeroRighe).style("fill", "eeeeee");
            sheet1.range("DF1:" + "DQ" + numeroRighe).style("fill", "d2d2d2");
            sheet1.range("DR1:" + "EC" + numeroRighe).style("fill", "eeeeee");
            sheet1.range("ED1:" + "EO" + numeroRighe).style("fill", "d2d2d2");
            

            sheet1.range("EP1:" + "FA" + numeroRighe).style("fill", "f4cccc");
            range.style("border", "thin");
            sheet1.range("EP1:" + "FA" + numeroRighe).style("borderStyle", "medium");
            sheet1.range("EP1:" + "FA" + numeroRighe).style("bold", true);
            sheet1.range("A" + (numeroRighe - 11) + ":" + "EO" + (numeroRighe)).style("borderStyle", "medium");
            sheet1.range("A" + (numeroRighe - 11) + ":" + "EO" + (numeroRighe)).style("bold", true);
            


                
                //FOGLIO 2
                
                const sheet2 = workbook.addSheet('Riepilogo');
                sheet2.cell("B9").value("gg");
                sheet2.cell("C9").value("n° dip");
                sheet2.cell("D9").value("tot");
                sheet2.cell("E9").value("car");
                sheet2.cell("F9").value("INPS");
                sheet2.cell("G9").value("tot");
                sheet2.cell("H9").value("INAIL");
                sheet2.cell("I9").value("tot");
                sheet2.cell("J9").value("c.b.");
                sheet2.cell("K9").value("tot");
                sheet2.cell("L9").value("104");
                sheet2.cell("M9").value("tot");
                sheet2.cell("N9").value("avis");
                sheet2.cell("O9").value("tot");
                sheet2.cell("P9").value("mat");
                sheet2.cell("Q9").value("tot");
                sheet2.cell("R9").value("mat fac");
                sheet2.cell("S9").value("tot");
                sheet2.cell("T9").value("ps");
                sheet2.cell("U9").value("tot");
                sheet2.cell("V9").value("cong obb papà");
                sheet2.cell("W9").value("tot");
                sheet2.cell("X9").value("mal osp");
                sheet2.cell("Y9").value("tot");


                sheet2.cell("Z9").value("tot gg");
                sheet2.cell("AA9").value("%Ass.");

                sheet2.cell("F10").value("gg");
                sheet2.cell("G10").value("%");
                sheet2.cell("H10").value("gg");
                sheet2.cell("I10").value("%");
                sheet2.cell("J10").value("gg");
                sheet2.cell("K10").value("%");
                sheet2.cell("L10").value("gg");
                sheet2.cell("M10").value("%");
                sheet2.cell("N10").value("gg");
                sheet2.cell("O10").value("%");
                sheet2.cell("P10").value("gg");
                sheet2.cell("Q10").value("%");
                sheet2.cell("R10").value("gg");
                sheet2.cell("S10").value("%");
                sheet2.cell("T10").value("gg");
                sheet2.cell("U10").value("%");
                sheet2.cell("V10").value("gg");
                sheet2.cell("W10").value("%");
                sheet2.cell("X10").value("gg");
                sheet2.cell("Y10").value("%");

                sheet2.cell("AA10").value("%");

                sheet2.cell("A26").value("Tot. Malattia");
                sheet2.cell("A27").value("% carenza");
                sheet2.cell("A28").value("% inps");

                let year = dataInizioInput.getFullYear()
                let arrayGiorniMeseData = [startOfMonth(new Date(year, 0, 1, 12, 0, 0)), startOfMonth(new Date(year, 1, 1, 12, 0, 0)), startOfMonth(new Date(year, 2, 1, 12, 0, 0)), startOfMonth(new Date(year, 3, 1, 12, 0, 0)),startOfMonth(new Date(year, 4, 1, 12, 0, 0)), startOfMonth(new Date(year, 5, 1, 12, 0, 0)), startOfMonth(new Date(year, 6, 1, 12, 0, 0)), startOfMonth(new Date(year, 7, 1, 12, 0, 0)), startOfMonth(new Date(year, 8, 1, 12, 0, 0)), startOfMonth(new Date(year, 9, 1, 12, 0, 0)), startOfMonth(new Date(year, 10, 1, 12, 0, 0)), startOfMonth(new Date(year, 11, 1, 12, 0, 0))]
                let arrayGiorniMeseDataFine = [lastDayOfMonth(new Date(year, 0, 1, 12, 0, 0)), lastDayOfMonth(new Date(year, 1, 1, 12, 0, 0)), lastDayOfMonth(new Date(year, 2, 1, 12, 0, 0)), lastDayOfMonth(new Date(year, 3, 1, 12, 0, 0)),lastDayOfMonth(new Date(year, 4, 1, 12, 0, 0)), lastDayOfMonth(new Date(year, 5, 1, 12, 0, 0)), lastDayOfMonth(new Date(year, 6, 1, 12, 0, 0)), lastDayOfMonth(new Date(year, 7, 1, 12, 0, 0)), lastDayOfMonth(new Date(year, 8, 1, 12, 0, 0)), lastDayOfMonth(new Date(year, 9, 1, 12, 0, 0)), lastDayOfMonth(new Date(year, 10, 1, 12, 0, 0)), lastDayOfMonth(new Date(year, 11, 1, 12, 0, 0))]
                let arrayGiorniMese = [arrayGiorniMeseDataFine[0].getDate(), arrayGiorniMeseDataFine[1].getDate(), arrayGiorniMeseDataFine[2].getDate(), arrayGiorniMeseDataFine[3].getDate(), arrayGiorniMeseDataFine[4].getDate(), arrayGiorniMeseDataFine[5].getDate(), arrayGiorniMeseDataFine[6].getDate(), arrayGiorniMeseDataFine[7].getDate(), arrayGiorniMeseDataFine[8].getDate(), arrayGiorniMeseDataFine[9].getDate(), arrayGiorniMeseDataFine[10].getDate(), arrayGiorniMeseDataFine[11].getDate()]
                let arrayDipendentiAttiviMese = []
                
                for(var i=11; i<23; i++){
                    sheet2.cell("A"+i).value(generaMese(i-11).toUpperCase());
                    sheet2.cell("B"+i).value(arrayGiorniMese[i-11]);
                    arrayDipendentiAttiviMese[i-11] = checkDipendentiAttivi(arrayDipendenti, arrayGiorniMeseData[i-11])
                    sheet2.cell("C"+i).value(arrayDipendentiAttiviMese[i-11]);
                    sheet2.cell("D"+i).value(arrayDipendentiAttiviMese[i-11] * arrayGiorniMese[i-11]);
                    const indexCarenza = data.findIndex(item => item.dipendente == "Tot. Carenza");
                    if(indexCarenza >= 0){
                        sheet2.cell("E"+i).value(data[indexCarenza]["car_"+generaMese(i-11)]);
                    }
                    const indexINPS = data.findIndex(item => item.dipendente == "Tot. inps");
                    if(indexINPS >= 0){
                        sheet2.cell("F"+i).value(data[indexINPS]["m_"+generaMese(i-11)]);
                        sheet2.cell("G"+i).value((((workbook.sheet("Riepilogo").cell("E"+i).value() + workbook.sheet("Riepilogo").cell("F"+i).value())/workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const indexINAIL = data.findIndex(item => item.dipendente == "Tot. inf");
                    if(indexINAIL >= 0){
                        sheet2.cell("H"+i).value(data[indexINAIL]["i_"+generaMese(i-11)]);
                        sheet2.cell("I"+i).value(((workbook.sheet("Riepilogo").cell("H"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const indexCB = data.findIndex(item => item.dipendente == "Tot. Cong. Bien.");
                    if(indexCB >= 0){
                        sheet2.cell("J"+i).value(data[indexCB]["cong_bien_"+generaMese(i-11)]);
                        sheet2.cell("K"+i).value(((workbook.sheet("Riepilogo").cell("J"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const index104 = data.findIndex(item => item.dipendente == "Tot. 104");
                    if(index104 >= 0){
                        sheet2.cell("L"+i).value(data[index104]["ph_"+generaMese(i-11)]);
                        sheet2.cell("M"+i).value(((workbook.sheet("Riepilogo").cell("L"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const indexAvis = data.findIndex(item => item.dipendente == "Tot. Avis");
                    if(indexAvis >= 0){
                        sheet2.cell("N"+i).value(data[indexAvis]["avis_"+generaMese(i-11)]);
                        sheet2.cell("O"+i).value(((workbook.sheet("Riepilogo").cell("N"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const indexMat = data.findIndex(item => item.dipendente == "Tot. Mat.");
                    if(indexMat >= 0){
                        sheet2.cell("P"+i).value(data[indexMat]["mat_"+generaMese(i-11)]);
                        sheet2.cell("Q"+i).value(((workbook.sheet("Riepilogo").cell("P"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const indexMatFac = data.findIndex(item => item.dipendente == "Tot. Mat.");
                    if(indexMatFac >= 0){
                        sheet2.cell("R"+i).value(data[indexMatFac]["mat_fac_"+generaMese(i-11)]);
                        sheet2.cell("S"+i).value(((workbook.sheet("Riepilogo").cell("R"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const indexPermSind = data.findIndex(item => item.dipendente == "Tot. Perm. Sind.");
                    if(indexPermSind >= 0){
                        sheet2.cell("T"+i).value(data[indexPermSind]["perm_sind_"+generaMese(i-11)]);
                        sheet2.cell("U"+i).value(((workbook.sheet("Riepilogo").cell("T"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }

                    const indexCongObbPapa = data.findIndex(item => item.dipendente == "Tot. Cong. Obbl. Papà");
                    if(indexCongObbPapa >= 0){
                        sheet2.cell("V"+i).value(data[indexCongObbPapa]["cong_obbl_papa_"+generaMese(i-11)]);
                        sheet2.cell("W"+i).value(((workbook.sheet("Riepilogo").cell("V"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }
                    const indexMalOsp = data.findIndex(item => item.dipendente == "Tot. Mal. Osp.");
                    if(indexMalOsp >= 0){
                        sheet2.cell("X"+i).value(data[indexMalOsp]["malat_osp_"+generaMese(i-11)]);
                        sheet2.cell("Y"+i).value(((workbook.sheet("Riepilogo").cell("X"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));
                    }

                    sheet2.cell("Z"+i).value(workbook.sheet("Riepilogo").cell("E"+i).value() + workbook.sheet("Riepilogo").cell("F"+i).value() + workbook.sheet("Riepilogo").cell("H"+i).value() + workbook.sheet("Riepilogo").cell("L"+i).value() + workbook.sheet("Riepilogo").cell("J"+i).value() + workbook.sheet("Riepilogo").cell("N"+i).value() + workbook.sheet("Riepilogo").cell("P"+i).value() + workbook.sheet("Riepilogo").cell("R"+i).value() + workbook.sheet("Riepilogo").cell("T"+i).value() + workbook.sheet("Riepilogo").cell("V"+i).value() + workbook.sheet("Riepilogo").cell("X"+i).value());
                    sheet2.cell("AA"+i).value(((workbook.sheet("Riepilogo").cell("Z"+i).value() / workbook.sheet("Riepilogo").cell("D"+i).value())*100).toFixed(2));

                    
                }
                sheet2.cell("A23").value("Totali");
                sheet2.cell("A24").value("Medie");
                sheet2.cell("B23").value(sommaValoriColonna(workbook, "Riepilogo", "B", 11, 23));
                sheet2.cell("D23").value(sommaValoriColonna(workbook, "Riepilogo", "D", 11, 23));
                sheet2.cell("E23").value(sommaValoriColonna(workbook, "Riepilogo", "E", 11, 23));
                sheet2.cell("E24").value(MediaValoriColonna(workbook, "Riepilogo", "E", 11, 23, meseDivisore));
                sheet2.cell("F23").value(sommaValoriColonna(workbook, "Riepilogo", "F", 11, 23));
                sheet2.cell("F24").value(MediaValoriColonna(workbook, "Riepilogo", "F", 11, 23, meseDivisore));
                sheet2.cell("G23").value(sommaValoriColonna(workbook, "Riepilogo", "G", 11, 23));
                sheet2.cell("G24").value(MediaValoriColonna(workbook, "Riepilogo", "G", 11, 23, meseDivisore));
                sheet2.cell("H23").value(sommaValoriColonna(workbook, "Riepilogo", "H", 11, 23));
                sheet2.cell("H24").value(MediaValoriColonna(workbook, "Riepilogo", "H", 11, 23, meseDivisore));
                sheet2.cell("I23").value(sommaValoriColonna(workbook, "Riepilogo", "I", 11, 23));
                sheet2.cell("I24").value(MediaValoriColonna(workbook, "Riepilogo", "I", 11, 23, meseDivisore));
                sheet2.cell("J23").value(sommaValoriColonna(workbook, "Riepilogo", "J", 11, 23));
                sheet2.cell("J24").value(MediaValoriColonna(workbook, "Riepilogo", "J", 11, 23, meseDivisore));
                sheet2.cell("K23").value(sommaValoriColonna(workbook, "Riepilogo", "K", 11, 23));
                sheet2.cell("K24").value(MediaValoriColonna(workbook, "Riepilogo", "K", 11, 23, meseDivisore));
                sheet2.cell("L23").value(sommaValoriColonna(workbook, "Riepilogo", "L", 11, 23));
                sheet2.cell("L24").value(MediaValoriColonna(workbook, "Riepilogo", "L", 11, 23, meseDivisore));
                sheet2.cell("M23").value(sommaValoriColonna(workbook, "Riepilogo", "M", 11, 23));
                sheet2.cell("M24").value(MediaValoriColonna(workbook, "Riepilogo", "M", 11, 23, meseDivisore));
                sheet2.cell("N23").value(sommaValoriColonna(workbook, "Riepilogo", "N", 11, 23));
                sheet2.cell("N24").value(MediaValoriColonna(workbook, "Riepilogo", "N", 11, 23, meseDivisore));
                sheet2.cell("O23").value(sommaValoriColonna(workbook, "Riepilogo", "O", 11, 23));
                sheet2.cell("O24").value(MediaValoriColonna(workbook, "Riepilogo", "O", 11, 23, meseDivisore));
                sheet2.cell("P23").value(sommaValoriColonna(workbook, "Riepilogo", "P", 11, 23));
                sheet2.cell("P24").value(MediaValoriColonna(workbook, "Riepilogo", "P", 11, 23, meseDivisore));
                sheet2.cell("Q23").value(sommaValoriColonna(workbook, "Riepilogo", "Q", 11, 23));
                sheet2.cell("Q24").value(MediaValoriColonna(workbook, "Riepilogo", "Q", 11, 23, meseDivisore));
                sheet2.cell("R23").value(sommaValoriColonna(workbook, "Riepilogo", "R", 11, 23));
                sheet2.cell("R24").value(MediaValoriColonna(workbook, "Riepilogo", "R", 11, 23, meseDivisore));
                sheet2.cell("S23").value(sommaValoriColonna(workbook, "Riepilogo", "S", 11, 23));
                sheet2.cell("S24").value(MediaValoriColonna(workbook, "Riepilogo", "S", 11, 23, meseDivisore));
                sheet2.cell("T23").value(sommaValoriColonna(workbook, "Riepilogo", "T", 11, 23));
                sheet2.cell("T24").value(MediaValoriColonna(workbook, "Riepilogo", "T", 11, 23, meseDivisore));
                sheet2.cell("U23").value(sommaValoriColonna(workbook, "Riepilogo", "U", 11, 23));
                sheet2.cell("U24").value(MediaValoriColonna(workbook, "Riepilogo", "U", 11, 23, meseDivisore));
                sheet2.cell("V23").value(sommaValoriColonna(workbook, "Riepilogo", "V", 11, 23));
                sheet2.cell("V24").value(MediaValoriColonna(workbook, "Riepilogo", "V", 11, 23, meseDivisore));
                sheet2.cell("W23").value(sommaValoriColonna(workbook, "Riepilogo", "W", 11, 23));
                sheet2.cell("W24").value(MediaValoriColonna(workbook, "Riepilogo", "W", 11, 23, meseDivisore));
                sheet2.cell("X23").value(sommaValoriColonna(workbook, "Riepilogo", "X", 11, 23));
                sheet2.cell("X24").value(MediaValoriColonna(workbook, "Riepilogo", "X", 11, 23, meseDivisore));
                sheet2.cell("Y23").value(sommaValoriColonna(workbook, "Riepilogo", "Y", 11, 23));
                sheet2.cell("Y24").value(MediaValoriColonna(workbook, "Riepilogo", "Y", 11, 23, meseDivisore));
                sheet2.cell("Z23").value(sommaValoriColonna(workbook, "Riepilogo", "Z", 11, 23));
                sheet2.cell("Z24").value(MediaValoriColonna(workbook, "Riepilogo", "Z", 11, 23, meseDivisore));
                sheet2.cell("AA23").value(sommaValoriColonna(workbook, "Riepilogo", "AA", 11, 23));
                sheet2.cell("AA24").value(MediaValoriColonna(workbook, "Riepilogo", "AA", 11, 23, meseDivisore));

                
                
                sheet2.cell("B26").value(((workbook.sheet("Riepilogo").cell("E23").value() + workbook.sheet("Riepilogo").cell("F23").value())).toFixed(2));
                sheet2.cell("B27").value(((workbook.sheet("Riepilogo").cell("E23").value() / workbook.sheet("Riepilogo").cell("B26").value())*100).toFixed(2));
                sheet2.cell("B28").value(((workbook.sheet("Riepilogo").cell("F23").value() / workbook.sheet("Riepilogo").cell("B26").value())*100).toFixed(2));


                sheet2.range("B9:AA9").style("borderStyle", "medium");
                sheet2.range("A11:AA24").style("borderStyle", "medium");
                sheet2.range("B9:AA9").style("bold", true);
                sheet2.range("A23:AA23").style("bold", true);
                sheet2.range("A24:AA24").style("bold", true);
                sheet2.range("A25:A28").style("bold", true);
                sheet2.range("A11:A22").style("bold", true);
                workbook.deleteSheet("Sheet1");

                return workbook.outputAsync().then((res) => {
                    saveAs(res, "file assenteismo.xlsx");
                    setLoadingRichieste(false)
                });
            
        });
    }

    return (
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Assenteismo - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_inner_100">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Assenteismo</div>
                            </div>
                            <div className="dati_container">
                                <div className="row row_datapicker">
                                    <div className="item_1 filtro_wrap_incarichi">
                                        {false ? 
                                        <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data inizio</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataInizioInput}
                                                        maxDetail={"decade"}
                                                        format="yyyy"
                                                        onChange={onChangeRangeDateInizio}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            ''
                                        }
                                        <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                            <div className="w-form">
                                                <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                    <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                    <label className="w-radio">
                                                        <input type="checkbox" data-name="Radio 2" id="radio-2" name="radio" value={gpgSelected} className="w-form-formradioinput w-radio-input" onChange={e => setGpgSelected(!gpgSelected)} checked={gpgSelected} />
                                                        <span className="label_text w-form-label" htmlFor="radio-2">GPG</span>
                                                    </label>
                                                </form>
                                            </div>
                                        </div>
                                        <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                            <div className="w-form">
                                                <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                    <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                    <label className="w-radio">
                                                        <input type="checkbox" data-name="Radio 2" id="radio-2" name="radio" value={otsSelected} className="w-form-formradioinput w-radio-input" onChange={e => setOtsSelected(!otsSelected)} checked={otsSelected} />
                                                        <span className="label_text w-form-label" htmlFor="radio-2">OTS</span>
                                                    </label>
                                                </form>
                                            </div>
                                        </div>
                                        <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                            <div className="w-form">
                                                <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                    <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                    <label className="w-radio">
                                                        <input type="checkbox" data-name="Radio 2" id="radio-2" name="radio" value={somministratoSelected} className="w-form-formradioinput w-radio-input" onChange={e => setSomministratoSelected(!somministratoSelected)} checked={somministratoSelected} />
                                                        <span className="label_text w-form-label" htmlFor="radio-2">Somministrato</span>
                                                    </label>
                                                </form>
                                            </div>
                                        </div>
                                        {user && user.role == 'organizzazione' ?
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                            <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filiale</div>
                                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idCliente" onChange={e => onChangeFiltraDipendentiPerFiliale(e.target.value)} required>
                                                            {filialiList.map((elementoFiliale, i) => (
                                                                <option key={i} value={i} selected={elementoFiliale._id == filtraFiliale} >{elementoFiliale.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                            </div>
                                            : ''
                                            }
                                    </div>
                                </div>
                                <div className="fun_quadrature_ore">
                                    <div className="row row_datapicker">
                                        <div className="item_1 filtro_wrap_incarichi">
                                            {true ?
                                                <>
                                                    <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                        <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data inizio</div>
                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                            <DatePicker
                                                                value={dataInizioInput}
                                                                format="dd/MM/y"
                                                                maxDetail={"month"}
                                                                weekPicker
                                                                onChange={onChangeRangeDateInizio}
                                                                locale="it-IT"
                                                                clearIcon={null}
                                                                weekStartDayIndex={1}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                        <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data fine</div>
                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                            <DatePicker
                                                                value={dataFineInput}
                                                                format="dd/MM/y"
                                                                maxDetail={"month"}
                                                                weekPicker
                                                                onChange={onChangeRangeDateFine}
                                                                locale="it-IT"
                                                                clearIcon={null}
                                                                weekStartDayIndex={1}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                ''
                                            }
                                            {loadingRichieste ? <Spinner /> :
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={() => { onClickGeneraAssenteismo() }} className="button w-button">Genera Excel</a>
                                                    </form>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Assenteismo.protoTypes = {
    getGiorniEccezioneAssenteismo: PropTypes.func.isRequired,
    getFestivita: PropTypes.func.isRequired,
    getFiliali: PropTypes.func.isRequired,
    dipendente: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    impostazione: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    dipendente: state.dipendente,
    impostazione: state.impostazione,
    auth: state.auth,
    filiale: state.filiale,
    alert: state.alert
});

export default connect(mapStateToProps, { getGiorniEccezioneAssenteismo, getFestivita, getFiliali })(Assenteismo);