import axios from 'axios';
import { GET_DIPENDENTE, GET_DIPENDENTI, GET_DIPENDENTI_CONTRATTO_JOB, GET_DIPENDENTI_CONTRATTO, GET_DIPENDENTI_JOB, FILTRA_DIPENDENTI, CREA_DIPENDENTE, MODIFICA_DIPENDENTE, GET_GIORNI_ECCEZIONE_DIPENDENTE, AGGIUNGI_GIORNO_ECCEZIONE, MODIFICA_GIORNO_ECCEZIONE, AGGIUNGI_GIORNO_ECCEZIONE_LUNGO, ELIMINA_GIORNO_ECCEZIONE, ELIMINA_GIORNO_ECCEZIONE_LUNGO, ELIMINA_GIORNO_ECCEZIONE_LUNGO_RICHIESTA, CONFERMA_GIORNO_ECCEZIONE, CONFERMA_GIORNI_ECCEZIONE, LOADING_DIPENDENTI, GET_GIORNI_ECCEZIONE, GET_GIORNI_ECCEZIONE_ASSENTEISMO, GET_INCARICHI_DIPENDENTE, GET_INCARICHI_TUTTI_DIPENDENTI_SALVATI, UPLOAD_DOCUMENTO, ELIMINA_DOCUMENTO, UPLOAD_ERROR, SALVA_INCARICHI_DIPENDENTE, GET_INCARICHI_DIPENDENTE_SALVATI, CLEAN_DIPENDENTI, CLEAN_GIORNI_ECCEZIONE, CLEAN_INCARICHI_PDF_DIPENDENTI, CLEAN_INCARICHI_DIPENDENTE, ELIMINA_PDF, DIPENDENTE_ERROR } from './types';
import { setAlert } from './alert';


export const getDipendenti = (page, pagination, visualizzaNonAttivi) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        page: page,
        pagination: pagination,
        visualizzaNonAttivi: visualizzaNonAttivi
        }); 
    try{
        const res = await axios.post('/api/dipendente/dipendenti', body, config);
        dispatch({
            type: GET_DIPENDENTI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getDipendentiAmministrazione = (page, pagination, mostraDipendentiCessati) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        page: page,
        pagination: pagination,
        mostraDipendentiCessati: mostraDipendentiCessati
        }); 
    try{
        const res = await axios.post('/api/dipendente/dipendentiAmministrazione', body, config);
        dispatch({
            type: GET_DIPENDENTI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraDipendenti = (parola, page, pagination, visualizzaNonAttivi) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        page: page,
        pagination: pagination,
        visualizzaNonAttivi: visualizzaNonAttivi
    });
    try{
        const res = await axios.post('/api/dipendente/filtraDipendenti', body, config);
            dispatch({
                type: FILTRA_DIPENDENTI,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getDipendente = (idDipendente) => async dispatch => {
    try{
        const res = await axios.get(`/api/dipendente/dipendente/${idDipendente}`);
        dispatch({
            type: GET_DIPENDENTE,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const creaNuovoDipendente = (
    {
        nome, 
        cognome, 
        codicefiscale, 
        matricola,
        luogoRes, 
        provRes, 
        cap, 
        indirizzo, 
        telefono1, 
        telefono2, 
        email,
        luogoNasc, 
        provNasc, 
        dataNasc,
        cadenza,
        dataInizioCadenza,
        dataInizioAss,
        dataFineAss,
        dipendenza,
        percBancaOre,
        bancaOreIniziale,
        ferieIniziale,
        permessiIniziale,
        qualifica,
        note,
        ferOrd,
        ferStr,
        prestabile,
        eccedenza,
        idMansioni,
        sussidiari,
        nomeSussidiari,
        partTime,
        somministrato
    }
) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(
        {
            nome, 
            cognome, 
            codicefiscale, 
            matricola,
            luogoRes, 
            provRes, 
            cap, 
            indirizzo, 
            telefono1, 
            telefono2, 
            email,
            luogoNasc, 
            provNasc, 
            dataNasc,
            cadenza,
            dataInizioCadenza,
            dataInizioAss,
            dataFineAss,
            dipendenza,
            percBancaOre,
            bancaOreIniziale,
            ferieIniziale,
            permessiIniziale,
            qualifica,
            note,
            ferOrd,
            ferStr,
            prestabile,
            eccedenza,
            idMansioni,
            sussidiari,
            nomeSussidiari,
            partTime,
            somministrato
        }
    );
    try{
        const res = await axios.post('/api/dipendente/nuovoDipendente', body, config);
        dispatch({
            type: CREA_DIPENDENTE,
            payload: res.data
        });
        dispatch(setAlert('Nuovo Dipendente creato!', 'success'));

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const modificaDipendente = ({
    idDipendente,
    nome, 
    cognome, 
    codicefiscale, 
    matricola,
    luogoRes, 
    provRes, 
    cap, 
    indirizzo, 
    telefono1, 
    telefono2, 
    email,
    luogoNasc, 
    provNasc, 
    dataNasc,
    cadenza,
    dataInizioCadenza,
    dataInizioAss,
    dataFineAss,
    dipendenza,
    percBancaOre,
    bancaOreIniziale,
    ferieIniziale,
    permessiIniziale,
    qualifica,
    note,
    ferOrd,
    ferStr,
    prestabile,
    eccedenza,
    idMansioni,
    sussidiari,
    nomeSussidiari,
    tagliaPantaloni,
    tagliaCamicia,
    tagliaMaglione,
    tagliaGiacca,
    tagliaScarpa,
    documenti,
    partTime,
    somministrato,
    documentiTiroASegno
}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idDipendente,
        nome, 
        cognome, 
        codicefiscale, 
        matricola,
        luogoRes, 
        provRes, 
        cap, 
        indirizzo, 
        telefono1, 
        telefono2, 
        email,
        luogoNasc, 
        provNasc, 
        dataNasc,
        cadenza,
        dataInizioCadenza,
        dataInizioAss,
        dataFineAss,
        dipendenza,
        percBancaOre,
        bancaOreIniziale,
        ferieIniziale,
        permessiIniziale,
        qualifica,
        note,
        ferOrd,
        ferStr,
        prestabile,
        eccedenza,
        idMansioni,
        sussidiari,
        nomeSussidiari,
        tagliaPantaloni,
        tagliaCamicia,
        tagliaMaglione,
        tagliaGiacca,
        tagliaScarpa,
        documenti,
        partTime,
        somministrato,
        documentiTiroASegno
    }); 
    try{
        const res = await axios.post('/api/dipendente/modificaDipendente', body, config);
        dispatch(setAlert('Dipendente modificato con successo!', 'success'));
        dispatch({
            type: MODIFICA_DIPENDENTE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};



export const getGiorniEccezioneDipendente = (idDipendente, page, pagination, dataInizio, dataFine) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idDipendente: idDipendente,
        page: page,
        pagination: pagination,
        dataInizio: dataInizio,
        dataFine: dataFine
        }); 
    try{
        const res = await axios.post('/api/dipendente/giorniEccezioneDipendente', body, config);
        dispatch({
            type: GET_GIORNI_ECCEZIONE_DIPENDENTE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const aggiungiGiornoEccezione = ({ idDipendente, disponibilita, dataRichiesta, oraInizio, oraFine, resti, nProtocollo }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idDipendente, disponibilita, dataRichiesta, oraInizio, oraFine, resti, nProtocollo }); 
    try{
        const res = await axios.post('/api/dipendente/aggiungiGiornoEccezione', body, config);
        dispatch(setAlert('Giorno eccezione aggiunto con successo!', 'success', 'add_giorno_eccezione'));
        dispatch({
            type: AGGIUNGI_GIORNO_ECCEZIONE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const togliFerieAggiungiGiornoEccezione = ({ idDipendente, disponibilita, dataRichiesta, oraInizio, oraFine, resti, nProtocollo }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idDipendente, disponibilita, dataRichiesta, oraInizio, oraFine, resti, nProtocollo }); 
    try{
        const res = await axios.post('/api/dipendente/togliFerieAggiungiGiornoEccezione', body, config);
        dispatch(setAlert('Giorno eccezione aggiunto con successo!', 'success', 'add_giorno_eccezione'));
        dispatch({
            type: AGGIUNGI_GIORNO_ECCEZIONE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const modificaGiornoEccezione = ({ id, dipendente, dataInizio, dataFine, disponibilita, nProtocollo }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ id, dipendente, dataInizio, dataFine, disponibilita, nProtocollo }); 
    try{
        const res = await axios.post('/api/dipendente/modificaGiornoEccezione', body, config);
        dispatch(setAlert('Giorno eccezione modificato con successo!', 'success', 'add_giorno_eccezione'));
        dispatch({
            type: MODIFICA_GIORNO_ECCEZIONE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const aggiungiGiornoEccezioneLungo = ({ idDipendente, disponibilita, dataInizio, dataFine, nProtocollo }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idDipendente, disponibilita, dataInizio, dataFine, nProtocollo }); 
    try{
        const res = await axios.post('/api/dipendente/aggiungiGiornoEccezioneLungo', body, config);
        dispatch(setAlert('Giorno eccezione aggiunto con successo!', 'success', 'add_giorno_eccezione'));
        dispatch({
            type: AGGIUNGI_GIORNO_ECCEZIONE_LUNGO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const eliminaGiornoEccezione = (idGiornoEccezione) => async dispatch => {
    try{
        const res = await axios.get(`/api/dipendente/eliminaGiornoEccezione/${idGiornoEccezione}`);
        dispatch(setAlert('Giorno eccezione eliminato con successo!', 'success'));
        dispatch({
            type: ELIMINA_GIORNO_ECCEZIONE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const eliminaGiornoEccezioneLungo = (idGiornoEccezione) => async dispatch => {
    try{
        const res = await axios.get(`/api/dipendente/eliminaGiornoEccezioneLungo/${idGiornoEccezione}`);
        dispatch(setAlert('Giorno eccezione eliminato con successo!', 'success', 'delete_giorno_eccezione'));
        dispatch({
            type: ELIMINA_GIORNO_ECCEZIONE_LUNGO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const eliminaGiornoEccezioneLungoRichieste = ({ idGiornoEccezione, dataRichiesta, oraInizio, oraFine, resti }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idGiornoEccezione, dataRichiesta, oraInizio, oraFine, resti }); 
    try{
        const res = await axios.post('/api/dipendente/eliminaGiornoEccezioneLungoRichieste', body, config);
        dispatch(setAlert('Giorno eccezione eliminato con successo!', 'success', 'delete_giorno_eccezione'));
        dispatch({
            type: ELIMINA_GIORNO_ECCEZIONE_LUNGO_RICHIESTA,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const confermaGiornoEccezione = (idGiornoEccezione) => async dispatch => {
    try{
        const res = await axios.get(`/api/dipendente/confermaGiornoEccezione/${idGiornoEccezione}`);
        dispatch(setAlert('Giorno eccezione confermato con successo!', 'success'));
        dispatch({
            type: CONFERMA_GIORNO_ECCEZIONE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const confermaGiorniEccezione = ({ giorniEccezione, check }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ giorniEccezione, check }); 
    try{
        const res = await axios.post('/api/dipendente/confermaGiorniEccezione', body, config);
        if(check){
            dispatch(setAlert('Giorni confermati con successo!', 'success'));
        }else{
            dispatch(setAlert('Giorni sconfermati con successo!', 'success'));
        }
        dispatch({
            type: CONFERMA_GIORNI_ECCEZIONE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const uploadNuovoDocumento = ({ file, fileName, fileType, idDipendente, tipologia, dataScadenza }) => async dispatch => {
    axios.post("/api/signS3/nuovoDocumento",{
      fileName : fileName,
      fileType : fileType,
      idDipendente: idDipendente,
      tipologia: tipologia,
      dataScadenza: dataScadenza
    })
    .then(response => {
      var returnData = response.data.data.returnData;
      var signedRequest = returnData.signedRequest;
      var options = {
        headers: {
          'Content-Type': fileType
        }
      };
      axios.put(signedRequest,file,options)
      .then(res => {
          console.log(res)
        dispatch({
            type: UPLOAD_DOCUMENTO,
            payload: { "url" : response.data.data.urlImg, "type": fileType, "tipologia": tipologia, "dataScadenza": dataScadenza }
        });
        dispatch(setAlert('Allegato aggiunto con successo!', 'success'));
      })
      .catch(err => {
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: UPLOAD_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
      })
    })
    .catch(err => {
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: UPLOAD_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    })
};

export const eliminaDocumento = (idDipendente, url) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idDipendente: idDipendente,
        url: url
        });
    try{
        const res = await axios.post('/api/dipendente/eliminaDocumento', body, config);
        dispatch({
            type: LOADING_DIPENDENTI
        });
        dispatch(setAlert('Documento eliminato con successo!', 'success'));
        dispatch({
            type: ELIMINA_DOCUMENTO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: UPLOAD_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const getDipendentiContratto = ({idContratto, dataRichiesta}) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify({idContratto, dataRichiesta});
        const res = await axios.post('/api/dipendente/dipendentiContratto', body, config);
        dispatch({
            type: GET_DIPENDENTI,
            payload: res.data
        });
        return res.data
    }catch(err){
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getDipendentiContrattoJob = ({idContratto, dataRichiesta, resti, oraInizio, oraFine, idFiliale}) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify({idContratto, dataRichiesta, resti, oraInizio, oraFine, idFiliale});
        const res = await axios.post('/api/dipendente/dipendentiContrattoJob', body, config);
        dispatch({
            type: GET_DIPENDENTI_CONTRATTO_JOB,
            payload: res.data
        });
        return res.data
    }catch(err){
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getDipendentiCalendarioContrattoJob = ({idContratto, dataRichiesta, resti, oraInizio, oraFine, idFiliale}) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify({idContratto, dataRichiesta, resti, oraInizio, oraFine, idFiliale});
        const res = await axios.post('/api/dipendente/dipendentiCalendarioContrattoJob', body, config);
        dispatch({
            type: GET_DIPENDENTI_CONTRATTO_JOB,
            payload: res.data
        });
        return res.data
    }catch(err){
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getDipendentiCalendarioContratto = ({idContratto, dataRichiesta, resti, oraInizio, oraFine, idFiliale}) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify({idContratto, dataRichiesta, resti, oraInizio, oraFine, idFiliale});
        const res = await axios.post('/api/dipendente/dipendentiCalendarioContratto', body, config);
        dispatch({
            type: GET_DIPENDENTI_CONTRATTO,
            payload: res.data
        });
        return res.data
    }catch(err){
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getStateDipendentiContrattoJob = (id) => async dispatch => {
    try{
        const res = await axios.get(`/api/dipendente/getStateDipendentiContrattoJob/${id}`);
        if(res.data.state == 'completed'){
            dispatch({
                type: GET_DIPENDENTI_JOB,
                payload: res.data
            });
        }
    }catch(err){
        console.log(err)
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const getGiorniEccezione = ({dataInizio, dataFine, idFiliale}) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify({dataInizio, dataFine, idFiliale});
        const res = await axios.post('/api/dipendente/getGiorniEccezione', body, config);
        dispatch({
            type: GET_GIORNI_ECCEZIONE,
            payload: res.data
        });
        return res.data
    }catch(err){
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getGiorniEccezioneCalendario = ({dataInizio}) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify({dataInizio});
        const res = await axios.post('/api/dipendente/getGiorniEccezioneCalendario', body, config);
        dispatch({
            type: GET_GIORNI_ECCEZIONE,
            payload: res.data
        });
        return res.data
    }catch(err){
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getGiorniEccezioneAssenteismo = ({dataInizio, dataFine, idFiliale}) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify({dataInizio, dataFine, idFiliale});
        const res = await axios.post('/api/dipendente/getGiorniEccezioneAssenteismo', body, config);
        dispatch({
            type: GET_GIORNI_ECCEZIONE_ASSENTEISMO,
            payload: res.data
        });
        return res.data
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};



export const getIncarichiDipendente = ({dataInizio, dataFine, idDipendente}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({
        dataInizio,
        dataFine,
        idDipendente
        }); 
    try{
        const res = await axios.post('/api/dipendente/incarichiDipendente', body, config);
        dispatch({
            type: GET_INCARICHI_DIPENDENTE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getPDFIncarichiDipendenteSalvati = ({dataInizio, dataFine, idDipendente}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({
        dataInizio,
        dataFine,
        idDipendente
        }); 
    try{
        const res = await axios.post('/api/dipendente/getPDFIncarichiDipendenteSalvati', body, config);
        dispatch({
            type: GET_INCARICHI_DIPENDENTE_SALVATI,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const salvaPDFIncarichiDipendente = ({ incarichiDipendente, incarichiDipendenteModificati, dataInizio, dataFine, note, idDipendente, indennita }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({
        incarichiDipendente,
        idDipendente,
        dataInizio,
        dataFine,
        note,
        indennita
    }); 
    try{
        const res = await axios.post('/api/dipendente/salvaPDFIncarichiDipendente', body, config);
        dispatch({
            type: SALVA_INCARICHI_DIPENDENTE,
            payload: res.data
        });
        dispatch(setAlert('File salvato con successo!', 'success'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getIncarichiTuttiDipendentiSalvati = ({dataInizio, dataFine, idFiliale}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({
        dataInizio,
        dataFine,
        idFiliale
        }); 
    try{
        const res = await axios.post('/api/dipendente/incarichiTuttiDipendentiSalvati', body, config);
        dispatch({
            type: GET_INCARICHI_TUTTI_DIPENDENTI_SALVATI,
            payload: res.data
        });
        if(res.data.length > 0){
            dispatch(setAlert('PDF generati!', 'success', 'tutti_incarichi_dipendenti_salvati'));
        }else{
            dispatch(setAlert('Nessun PDF generato per questa data!', 'error', 'nessuno_incarico_dipendenti_salvato'));

        }
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const eliminaPDF = (idPdf) => async dispatch => {
    try{
        console.log(idPdf)
        const res = await axios.get(`/api/dipendente/eliminaPDF/${idPdf}`);
        dispatch({
            type: ELIMINA_PDF,
            payload: res.data
        });
        dispatch(setAlert('PDF eliminato!', 'success', 'tutti_incarichi_dipendenti_salvati'));

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DIPENDENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const cleanDipendenti = () => async dispatch => {
    dispatch({
        type: CLEAN_DIPENDENTI
    });
};

export const cleanGiorniEccezione = () => async dispatch => {
    dispatch({
        type: CLEAN_GIORNI_ECCEZIONE
    });
};

export const cleanIncarichiPDFDipendenti = () => async dispatch => {
    dispatch({
        type: CLEAN_INCARICHI_PDF_DIPENDENTI
    });
};

export const cleanIncarichiDipendente = () => async dispatch => {
    dispatch({
        type: CLEAN_INCARICHI_DIPENDENTE
    });
};